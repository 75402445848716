import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UserService } from 'src/app/_services/user.service';
import { getCurrentUser, State } from 'src/app/_store';

import { User } from '../../_models/user';
import { AlertService } from '../../_services/alert.service';
import { MailService } from '../../_services/mail.service';


@Component({
  selector: "app-send-mail-dialog",
  templateUrl: "./send-dialog-email.component.html",
  styleUrls: ["./send-dialog-email.component.scss"]
})
export class SendDialogMailComponent implements OnInit {
  recipients: string[];

  mailForm: UntypedFormGroup;

  currentUser: User;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<State>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SendDialogMailComponent>,
    public userService: UserService,
    public alertService: AlertService,
    public mailService: MailService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store.select(getCurrentUser).subscribe(currentUser => {
      this.currentUser = currentUser;
    })
    this.createForm();
    if (this.data) {
    }
  }

  ngOnInit() {
    this.recipients = this.data.recipients;
    this.initForm();
  }

  createForm() {
    let group = {
      sender: ["", [Validators.required, Validators.email]],
      subject: ["", Validators.required],
      template: ["", Validators.required],
      senderInBcc: "",
    };

    this.mailForm = this.fb.group(group);
  }

  initForm() {
    this.mailForm.patchValue({
      sender: this.currentUser.email,
      senderInBcc: true,
      subject: this.data.subject ? this.data.subject : `Comunicazione in merito al corso`,
      template: this.data.template ? this.data.template : `Gentilissimo referente aziendale,<br>
      la presente comunicazione riguarda il partecipante iscritto al corso in oggetto $PERSON_NAME$ $PERSON_SURNAME$<br>
      Comunichiamo che<br><br>
      Cordiali saluti,<br>
      <b>GEM BB S.r.l.</b><br>
      0549 - 960151`
    });
  }

  close() {
    this.dialogRef.close();
  }

  send() {
    const formModel = this.mailForm.value;
    this.mailService.sendMail(this.recipients, formModel.template,
      formModel.subject,
      formModel.sender,
      formModel.senderInBcc,
    ).subscribe(() => {
      this.alertService.showConfirmMessage(`E-mail inviata correttamente a ${this.recipients.length} persone`);
      this.close();
    }, error => {
      this.alertService.showErrorMessage(`Errore nell'invio e-mail`, error);
    })
  }
}
