import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-file-selection-dialog',
  templateUrl: './file-selection-dialog.component.html',
  styleUrls: ['./file-selection-dialog.component.scss']
})
export class FileSelectionDialogComponent implements OnInit {
  file: File;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FileSelectionDialogComponent>,
  ) { }

  ngOnInit() {
  }

  fileChanged(event) {
    this.file = event.target.files[0];
  }


  close() {
    this.dialogRef.close();
  }

  startImport() {
    this.dialogRef.close(this.file);
  }
}


