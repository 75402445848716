import { Observable } from "rxjs";
import { Org, OrgsFilter } from "./../../../_models/org";
import { OrgService } from "./../../../_services/org.service";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";

export class OrgsDataSource implements DataSource<Org> {
  private orgSubject = new BehaviorSubject<Org[]>([]);

  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.totalSubject.asObservable();

  constructor(private orgService: OrgService) {}

  connect(): Observable<Org[]> {
    return this.orgSubject.asObservable();
  }

  disconnect(): void {
    this.orgSubject.complete();
    this.loadingSubject.complete();
    this.totalSubject.complete();
  }

  loadOrgs(
    page: number = 0,
    perPage: number = 25,
    order: string = "name",
    direction: string = "",
    filter?: OrgsFilter,
    include?: string | string[]
  ) {
    this.loadingSubject.next(true);

    this.orgService
      .getOrgs(page, perPage, order, direction, filter, include)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        result => {
          this.totalSubject.next(result.total);
          this.orgSubject.next(result.data);
        },
        error => {
          console.error("Error", error);
          this.totalSubject.next(0);
          this.orgSubject.next([]);
        }
      );
  }
}
