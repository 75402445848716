import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfirmDialogComponent } from "../commons/confirm-dialog/confirm-dialog.component";
import { AlertDialogComponent } from "../commons/alert-dialog/alert-dialog.component";

@Injectable({ providedIn: "root" })
export class AlertService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) { }

  public showConfirmMessage(
    message: string,
    snackBarConfig?: MatSnackBarConfig
  ) {
    let snackBarRef = this.snackBar.open(
      message,
      "X",
      Object.assign({ duration: 2000 }, snackBarConfig)
    );
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }

  public showErrorMessage(message: string, error: any) {
    let errorMessage = error || "";
    if (error && error.error && error.error.message) {
      errorMessage = error.error.message;
    }
    this.showConfirmMessage(`${message} ${errorMessage}`, { duration: -1, verticalPosition: "top", panelClass: "error" });
  }

  public showConfirmDialog(
    title: string,
    message: string,
    confirmCheckMessage?: string
  ): Observable<any> {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.confirmMessage = message;
    dialogRef.componentInstance.checkMessage = confirmCheckMessage;

    return dialogRef.afterClosed();
  }

  public showAlertDialog(title: string, message: string): Observable<any> {
    let dialogRef = this.dialog.open(AlertDialogComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.alertMessage = message;

    return dialogRef.afterClosed();
  }
}
