<div role="group" class="example-tel-input-container" [formGroup]="form"
  [attr.aria-labelledby]="_formField?.getLabelId()">
  <mat-select placeholder="HH" formControlName="hours" required>
    <mat-option *ngFor="let hour of hours" [value]="hour" [disabled]="hour < min?.hours">{{hour | number:'2.0'}}</mat-option>
  </mat-select>
  :
  <mat-select placeholder="mm" formControlName="minutes" required>
    <mat-option *ngFor="let minute of minutes" [value]="minute">{{minute | number:'2.0'}}</mat-option>
  </mat-select>
</div>
