<header mat-dialog-title>Invio fattura di cortesia</header>
<mat-dialog-content>
  <form [formGroup]="mailForm" novalidate>
    <table style="width: 100%">
      <tr>
        <td style="width: 70%">
          <mat-form-field class="full-width-input">
            <input
              matInput
              placeholder="Oggetto"
              type="text"
              formControlName="subject"
              required
            />
            <mat-error *ngIf="mailForm.controls['subject'].errors">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </td>

        <td style="width: 30%">
          <mat-form-field class="full-width-input">
            <input
              matInput
              placeholder="Mittente"
              type="email"
              formControlName="sender"
              required
            />
          </mat-form-field>
        </td>
      </tr>
    </table>
    <div>
      <mat-checkbox
        #tooltip="matTooltip"
        matTooltip="Invia copia al mittente, in ccn"
        aria-label="send-in-ccn"
        formControlName="senderInBcc"
        >Copia al mittente
      </mat-checkbox>
    </div>
    <div style="padding: 5px">
      <label>Testo della mail</label>
      <ngx-wig formControlName="template"> </ngx-wig>
    </div>
    <ng-container>
      <h6>
        <i>Puoi usare questi codici nel testo della mail: </i>
      </h6>
      <ul>
        <li><strong>$CLIENT_NAME$</strong> - Nome del cliente</li>
      </ul>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="basic" mat-dialog-close>Annulla</button>
  <button
    mat-raised-button
    color="primary"
    type="button"
    (click)="send()"
    [disabled]="!mailForm.valid"
  >
    Conferma
  </button>
</mat-dialog-actions>
