<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    Filtri
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>Area</mat-label>
        <mat-select formControlName="area">
          <mat-option *ngFor="let area of PROJECT_AREAS" [value]="area">{{area}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>Classe</mat-label>
        <mat-select formControlName="class">
          <mat-option *ngFor="let projectClass of PROJECT_CLASSES" [value]="projectClass">{{projectClass}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>Numero Offerta</mat-label>
        <input matInput type="text" formControlName="offer_number"/>
      </mat-form-field>
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>Numero Ordine</mat-label>
        <input matInput type="text" formControlName="order_number"/>
      </mat-form-field>
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>Identificativo</mat-label>
        <input matInput type="text" formControlName="identifier"/>
      </mat-form-field>
    </div>
      <div class="u-flex-full">
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>Apertura commessa - Da</mat-label>
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="open_from" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>Apertura commessa - A</mat-label>
        <input matInput [matDatepicker]="fromDatePicker2" formControlName="open_to" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker2"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker2></mat-datepicker>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Cliente</mat-label>
        <app-org-field formControlName="client"></app-org-field>
        <button mat-button type="button" *ngIf="filtersForm.value['client']" matSuffix mat-icon-button
          aria-label="Clear" (click)="filtersForm.patchValue({client: null})">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Responsabile commessa</mat-label>
        <app-user-field formControlName="accountant"></app-user-field>
        <button mat-button type="button" *ngIf="filtersForm.value['accountant']" matSuffix mat-icon-button
          aria-label="Clear" (click)="filtersForm.patchValue({accountant: null})">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-checkbox formControlName="include_closed">Includi commesse chiuse</mat-checkbox>
      <ng-container *ngIf="filtersForm.get('include_closed').value">
        <mat-form-field class="u-full-width" floatLabel="always">
          <mat-label>Chiusura commessa - Da</mat-label>
          <input matInput [matDatepicker]="fromDatePickerClosedFrom" formControlName="closed_from" />
          <mat-datepicker-toggle matSuffix [for]="fromDatePickerClosedFrom"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePickerClosedFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="u-full-width" floatLabel="always">
          <mat-label>Chiusura commessa - A</mat-label>
          <input matInput [matDatepicker]="fromDatePickerClosedTo" formControlName="closed_to" />
          <mat-datepicker-toggle matSuffix [for]="fromDatePickerClosedTo"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePickerClosedTo></mat-datepicker>
        </mat-form-field>
      </ng-container>
    </div>
    <div class="u-flex-full">
      <span class="u-flex-full">
        <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">Filtra</button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>
