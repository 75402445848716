import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../_helpers/listResult.interface';
import { Org, OrgsFilter } from './../_models/org';
import { LaravelOrgService } from './laravel/laravel-org.service';
import { Employee } from 'src/app/_models/employee';

@Injectable({ providedIn: "root" })
export class OrgService {
  constructor(private laravelOrgService: LaravelOrgService) { }

  public addOrg(org: Org): Observable<Org> {
    return this.laravelOrgService.createOrg(org.toDTO(), org.logoFile).pipe(
      map(dto => {
        return new Org(dto);
      })
    );
  }

  public updateOrg(org: Org): Observable<Org> {
    return this.laravelOrgService
      .updateOrg(org.objectId, org.toDTO(), org.logoFile)
      .pipe(
        map(dto => {
          return new Org(dto);
        })
      );
  }

  public archiveOrg(org: Org): Observable<Org> {
    return this.laravelOrgService.deleteOrg(org.objectId).pipe(
      map(() => {
        return org;
      })
    );
  }

  public restoreOrg(org: Org): Observable<Org> {
    return this.laravelOrgService.restoreOrg(org.objectId).pipe(
      map(restored => {
        return new Org(restored);
      })
    );
  }

  public getOrg(orgId: number, include?: string | string[]): Observable<Org> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelOrgService
      .getOrgById(orgId, includes)
      .pipe(map(dto => new Org(dto)));
  }

  public getOrgs(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter?: OrgsFilter,
    include?: string | string[]
  ): Observable<ListResult<Org>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelOrgService
      .getOrgs(
        page,
        perPage,
        order,
        direction,
        filter.onlyPartners,
        filter.includeArchived,
        filter.queryString,
        includes
      )
      .pipe(
        map(response => {
          return {
            data: response.data.map(dto => new Org(dto)),
            total: response.total
          };
        })
      );
  }

  public importOrg(orgId: number, data: any): Observable<any> {
    return this.laravelOrgService.importOrg(orgId, data);
  }

  public isVATExisting(orgId: number, VAT: string): Observable<boolean> {
    return this.laravelOrgService.checkVATExisting(orgId, VAT);
  }

  public importEmployees(org: Org, csv: any): Observable<Employee[]> {
    return this.laravelOrgService
      .importEmployees(org.objectId, csv)
      .pipe(map(dtos => dtos.map(dto => new Employee(dto))));
  }

  public exportStatus(orgId: number): Observable<Blob> {
    return this.laravelOrgService
      .exportStatus(orgId)
      .pipe(map(data => new Blob([data], { type: "text/csv" })));
  }

  public exportStatusPartner(orgId: number): Observable<Blob> {
    return this.laravelOrgService
      .exportStatusPartner(orgId)
      .pipe(map(data => new Blob([data], { type: "text/csv" })));
  }
}
