import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';

import * as fromActivity from '../home/activities/store/activity.reducer';
import * as fromGoogle from '../home/google/store/google.reducer';
import * as fromArticle from '../home/store/reducers/article.reducer';
import * as fromProject from '../home/store/reducers/project.reducer';
import * as fromApp from './app.reducer';
import * as fromUser from './user.reducer';
import * as fromBillingLine from './billing-line.reducer';
import * as fromCostExtra from './cost-extra.reducer';
import * as fromInvoice from '../home/invoices/store/invoice.reducer';

export interface State {
  appState: fromApp.AppState;
  project: fromProject.ProjectState;
  article: fromArticle.ArticleState;
  google: fromGoogle.GoogleState;
  activity: fromActivity.ActivityState;
  user: fromUser.UserState;
  billingLine: fromBillingLine.BillingLineState;
  costExtra: fromCostExtra.CostExtraState;
  invoice: fromInvoice.InvoiceState;
}

export const reducers: ActionReducerMap<State> = {
  appState: fromApp.reducer,
  project: fromProject.reducer,
  article: fromArticle.reducer,
  google: fromGoogle.reducer,
  activity: fromActivity.reducer,
  user: fromUser.reducer,
  billingLine: fromBillingLine.reducer,
  costExtra: fromCostExtra.reducer,
  invoice: fromInvoice.reducer
};

export const metaReducers: MetaReducer<State>[] = [fromApp.clearState];

const getAppState = (state: State) => state.appState;

export const getCurrentUser = createSelector(
  getAppState,
  fromApp.currentUser
);

export const getServices = createSelector(
  getAppState,
  fromApp.services
);

export const getCurrentPageTitle = createSelector(
  getAppState,
  fromApp.currentPageTitle
);

export const getCurrentPageSlug = createSelector(
  getAppState,
  fromApp.currentPageSlug
);

export const selectProjectState = createFeatureSelector<fromProject.ProjectState>("project");
export const selectGoogleState = createFeatureSelector<fromGoogle.GoogleState>("google");
export const selectActivityState = createFeatureSelector<fromActivity.ActivityState>("activity");
export const selectUserState = createFeatureSelector<fromUser.UserState>("user");
export const selectArticleState = createFeatureSelector<fromArticle.ArticleState>("article");
export const selectBillingLineState = createFeatureSelector<fromBillingLine.BillingLineState>("billingLine");
export const selectCostExtraState = createFeatureSelector<fromCostExtra.CostExtraState>("costExtra");
export const selectInvoiceState = createFeatureSelector<fromInvoice.InvoiceState>("invoice");
