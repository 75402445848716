import { Action, createReducer, on } from '@ngrx/store';
import { CalendarDTO } from 'src/app/_models/calendar';
import * as GoogleActions from './google.actions';

export interface GoogleState {
  authUrl?: string,
  email?: string,
  calendars?: CalendarDTO[]
};

const initialState: GoogleState = {
  authUrl: null,
  email: null,
  calendars: []
};

const templateReducer = createReducer(
  initialState,
  on(GoogleActions.loadCompleted, (state, { authUrl, email }) => {
    return { ...state, authUrl, email };
  }),
  on(GoogleActions.disconnectCompleted, (state) => {
    return { ...state, token: null };
  }),
  on(GoogleActions.loadCalendarsCompleted, (state, { calendars }) => {
    return { ...state, calendars };
  }),
);

export function reducer(state: GoogleState | undefined, action: Action) {
  return templateReducer(state, action);
}
