import { Action, createReducer, on } from '@ngrx/store';
import { removeObjectFromArray } from 'src/app/_helpers/utils';
import { Invoice, InvoiceDTO, InvoiceFilters } from 'src/app/_models/invoice';

import * as InvoiceActions from './invoice.actions';

export interface InvoiceState {
  list: InvoiceDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: InvoiceFilters,
  dialogId: string,
  selectionDialogId: string,
  currentInvoice: InvoiceDTO,
  invoiceId: number,
  checkResponse: boolean,
  selectedInvoices: Invoice[]
};

const initialState: InvoiceState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  invoiceId: null,
  currentInvoice: null,
  checkResponse: null,
  selectedInvoices: []

};

const invoiceReducer = createReducer(
  initialState,
  on(InvoiceActions.loadInvoicesCompleted, (state, { invoices, currentPage, total, perPage, order, direction, includes }) => {
    return { ...state, list: invoices, currentPage, total, perPage, order, direction, includes };
  }),
  on(InvoiceActions.invoiceDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(InvoiceActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(InvoiceActions.loadInvoices, (state, { filters }) => {
    return { ...state, filters };
  }),
  on(InvoiceActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(InvoiceActions.loadCurrentInvoiceCompleted, (state, { currentInvoice }) => {
    return { ...state, currentInvoice };
  }),
  on(InvoiceActions.loadInvoiceCompleted, (state, { invoice }) => {
    return { ...state, currentInvoice: invoice };
  }),
  on(InvoiceActions.checkInvoiceCodeCompleted, (state, { response }) => {
    return { ...state, checkResponse: response };
  }),
  on(InvoiceActions.addSelectInvoices, (state, { invoice }) => {
    return { ...state, selectedInvoices: [...state.selectedInvoices, invoice] }
  }),
  on(InvoiceActions.removeSelectedInvoices, (state, { invoice }) => {
    const selectedInvoices = removeObjectFromArray<Invoice>(state.selectedInvoices, invoice);
    return { ...state, selectedInvoices }
  }),
  on(InvoiceActions.resetSelectedInvoices, (state) => {
    return { ...state, selectedInvoices: [] }
  }),

);

export function reducer(state: InvoiceState | undefined, action: Action) {
  return invoiceReducer(state, action);
}
