import { Action, createReducer, on } from '@ngrx/store';

import { User, UserDTO } from 'src/app/_models/user';
import * as UserActions from './user.actions';

export interface UserState {
    selectionDialogId: string,
    user: UserDTO,
    changePasswordDialogId: string,
};

const initialState: UserState = {
    selectionDialogId: null,
    user: null,
    changePasswordDialogId: null,
};

const userReducer = createReducer(
    initialState,
    on(UserActions.selectionDialogOpened, (state, { selectionDialogId }) => {
        return { ...state, selectionDialogId };
    }),
    on(UserActions.changePasswordDialogOpen, (state, { dialogId }) => {
        return { ...state, changePasswordDialogId:dialogId };
      }),
);

export function reducer(state: UserState | undefined, action: Action) {
    return userReducer(state, action);
}
