import { Action, createReducer, on } from '@ngrx/store';
import { ArticleDTO, ArticleFilters } from 'src/app/_models/article';

import * as ArticleActions from '../actions/article.actions';

export interface ArticleState {
  list: ArticleDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: Partial<ArticleFilters>,
  dialogId: string,
  selectionDialogId: string,
  article: ArticleDTO
};

const initialState: ArticleState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  article: null
};

const articleReducer = createReducer(
  initialState,
  on(ArticleActions.loadArticlesCompleted, (state, { articles, currentPage, total, perPage, order, direction, includes }) => {
    return { ...state, list: articles, currentPage, total, perPage, order, direction, includes };
  }),
  on(ArticleActions.articleDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ArticleActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ArticleActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: ArticleState | undefined, action: Action) {
  return articleReducer(state, action);
}

