import { createSelector } from '@ngrx/store';

import { selectUserState } from './index';
import { UserState } from './user.reducer';

// User Selectors
export const getSelectionDialogId = createSelector(
    selectUserState,
    (state: UserState) => state.selectionDialogId
);
export const getSelectedUser = createSelector(
    selectUserState,
    (state: UserState) => state.user
);
export const getChangePasswordDialogId = createSelector(
    selectUserState,
    (state: UserState) => state.changePasswordDialogId
  );