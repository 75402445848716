<header mat-dialog-title>
  <span *ngIf="!educationUnit"> Scegli Unità Formativa pianificata </span>
  <span *ngIf="educationUnit">
    Pianificazioni per {{ educationUnit.code }} - {{ educationUnit.name }}
  </span>
</header>
<mat-dialog-content>
  <div class="mat-elevation-z2">
    <mat-toolbar class="flex">
      <span></span>
      <form
        [formGroup]="filtersForm"
        (onSubmit)="reload()"
        novalidate
        (keyup.enter)="reload()"
      >
        <mat-form-field floatLabel="never">
          <mat-label>Da</mat-label>
          <input
            matInput
            [matDatepicker]="fromDatePicker"
            formControlName="from"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fromDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field floatLabel="never">
          <mat-label>A</mat-label>
          <input matInput [matDatepicker]="toDatePicker" formControlName="to" />
          <mat-datepicker-toggle
            matSuffix
            [for]="toDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
        </mat-form-field>

        <button type="button" mat-icon-button color="primary">
          <mat-icon aria-label="Start search button" (click)="reload()"
            >search</mat-icon
          >
        </button>
        <button
          type="button"
          mat-icon-button
          color="primary"
          *ngIf="!filtersForm.pristine"
        >
          <mat-icon
            aria-label="Clear search button"
            (click)="initFiltersForm(); reload()"
            >close</mat-icon
          >
        </button>
      </form>
    </mat-toolbar>
    <div
      [hidden]="
        (dataSource.loading$ | async) || (dataSource.total$ | async) == 0
      "
    >
      <mat-table
        #table
        [dataSource]="dataSource"
        class="table"
        matSort
        matSortActive="created_at"
        matSortDisableClear
        matSortDirection="asc"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && (allSelected | async)"
              [indeterminate]="selection.hasValue() && (allSelected | async)"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [disabled]="!canSelect(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header="startDate"
            >Data</mat-header-cell
          >
          <mat-cell *matCellDef="let row"
            >{{ row.start | date : "dd-MM-yyyy" }}
            {{ row.start | date : "HH:mm" }}->{{
              row.end | date : "HH:mm"
            }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef mat-sort-header="location"
            >Sede</mat-header-cell
          >
          <mat-cell *matCellDef="let row">{{ row.location }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="course">
          <mat-header-cell *matHeaderCellDef>Corso/i</mat-header-cell>
          <mat-cell *matCellDef="let row">{{
            row.plannedCourses | coursesPipe
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button
              mat-raised-button
              color="primary"
              [disabled]="!canSelect(row)"
              (click)="selectPlannedUnit(row)"
            >
              Seleziona
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator
        [length]="dataSource.total$ | async"
        [pageSize]="25"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    *ngIf="multiple"
    [disabled]="!(selection?.selected?.length > 0)"
    mat-raised-button
    color="primary"
    (click)="confirmMultiple()"
  >
    Conferma
  </button>
  <button mat-raised-button color="basic" (click)="close()">Chiudi</button>
</mat-dialog-actions>
