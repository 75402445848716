import { Component, HostListener, ElementRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

const DEFAULT_PLACEHOLDER_SRC = "assets/images/placeholder-logo.png";

@Component({
  selector: "app-logo-upload",
  templateUrl: "./logo-upload.component.html",
  styleUrls: ["./logo-upload.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LogoUploadComponent,
      multi: true
    }
  ]
})
export class LogoUploadComponent implements ControlValueAccessor {
  imageSrc: string | ArrayBuffer = DEFAULT_PLACEHOLDER_SRC;
  onChange: Function;
  file: File | null = null;
  disabled: boolean = false;

  @HostListener("change", ["$event.target.files"]) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.file = file;
    this.onChange(this.file);
  }

  constructor(private host: ElementRef<HTMLInputElement>) {}

  ngOnInit() {}

  writeValue(value) {
    // clear file input
    this.host.nativeElement.value = "";
    this.file = value;

    if (value) {
      if (typeof value == "string") {
        this.imageSrc = value;
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = () => {
          this.imageSrc = reader.result;
        };
      }
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {}

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageSrc = reader.result;
      };

      this.registerOnTouched;
    }
  }

  delete() {
    this.file = null;
    this.imageSrc = DEFAULT_PLACEHOLDER_SRC;
    this.onChange(null);
  }
}
