import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, forwardRef, HostBinding, Input, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NgControl, Validators} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Article, ArticleFilters} from 'src/app/_models/article';
import {AppState} from 'src/app/_store/app.reducer';

import * as ArticleActions from '../../home/store/actions/article.actions';


export const ARTICLE_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => ArticleFieldComponent),
};

@Component({
  selector: "app-article-field",
  templateUrl: "./article-field.component.html",
  styleUrls: ["./article-field.component.scss"],
  providers: [ARTICLE_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class ArticleFieldComponent
  implements ControlValueAccessor, MatFormFieldControl<Article> {
  private unsubscribe$ = new Subject<void>();
  private _value: Article;
  private _disabled = false;

  private _required = false;
  focused: boolean; //TODO
  controlType?: string = "article-selection-field";
  autofilled?: boolean;
  articleAriaDescribedBy?: string;

  @Input()
  articleFilters: Partial<ArticleFilters>;

  @Input()
  showExpenseFilter: boolean;

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    @Optional() @Self() public ngControl: NgControl
  ) {

    this.actions$
      .pipe(
        ofType(ArticleActions.articleSelected),
        takeUntil(this.unsubscribe$),
        map(({article}) => (article ? new Article(article) : null))

      )
      .subscribe((article) => (this.value = article));
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  stateChanges = new Subject<void>();
  id: string;

  get errorState(): boolean {
    return this.required && !this.value;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    //TODO
  }
  onContainerClick(event: MouseEvent): void {
    if (!this.disabled) {
      if ((event.target as Element).tagName.toLowerCase() != "mat-icon") {
        this.selectArticle();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectArticle() {
    this.store$.dispatch(ArticleActions.selectArticle({filters: this.articleFilters, showExpenseFilter: this.showExpenseFilter}));
  }

  clearArticle() {
    this.value = null;
  }

  set value(value: Article) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): Article {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: Article): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
