import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ListResultDTO } from '../../_helpers/listResult.interface';
import { formatDateForBackend } from '../../_helpers/utils';
import { PlannedUnitDTO } from '../../_models/planning';

@Injectable({ providedIn: "root" })
export class LaravelPlannedUnitService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/plannedUnit",
      list: environment["laravel"]["serverUrl"] + "/api/plannedUnits",
      store: environment["laravel"]["serverUrl"] + "/api/plannedUnit",
      delete: environment["laravel"]["serverUrl"] + "/api/plannedUnit",
      summary: environment["laravel"]["serverUrl"] + "/api/plannedUnit/summary",
    };
  }

  getPlannedUnitById(
    id: number,
    include?: string[]
  ): Observable<PlannedUnitDTO> {
    let params = {
      id: "" + id
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<PlannedUnitDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public getPlannedUnits(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    from: Date,
    to: Date,
    unit_id?: number,
    include?: string[]
  ): Observable<ListResultDTO<PlannedUnitDTO>> {
    if (per_page) {
      return this.getPaginatedResults(
        page,
        per_page,
        order,
        direction,
        from,
        to,
        unit_id,
        include
      );
    } else {
      return this.getAllResults(
        order,
        direction,
        from,
        to,
        unit_id,
        include
      ).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  private getPaginatedResults(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    from: Date,
    to: Date,
    unit_id?: number,
    include?: string[]
  ): Observable<ListResultDTO<PlannedUnitDTO>> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (unit_id) params["unit_id"] = "" + unit_id;
    if (include) params["include[]"] = include;
    if (from) params["from"] = "" + formatDateForBackend(from, true);
    if (to) params["to"] = "" + formatDateForBackend(to, true);

    return this.httpClient.get<ListResultDTO<PlannedUnitDTO>>(
      this.ROUTES.list,
      {
        params: new HttpParams({ fromObject: params })
      }
    );
  }

  private getAllResults(
    order: string,
    direction: string,
    from: Date,
    to: Date,
    unit_id?: number,
    include?: string[]
  ): Observable<PlannedUnitDTO[]> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (from) params["from"] = "" + formatDateForBackend(from, true);
    if (to) params["to"] = "" + formatDateForBackend(to, true);
    if (unit_id) params["unit_id"] = "" + unit_id;
    return this.httpClient.get<PlannedUnitDTO[]>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public createPlannedUnit(
    dto: PlannedUnitDTO,
    attachments?: any[]
  ): Observable<PlannedUnitDTO> {
    let formData: FormData = new FormData();
    formData.append("planned_unit", JSON.stringify(dto));
    if (attachments) {
      attachments.forEach((attachment, index) => {
        formData.append(`attachment_${index}`, attachment);
      });
    }
    return this.httpClient.post<PlannedUnitDTO>(this.ROUTES.store, formData);
  }

  public updatePlannedUnit(
    id: number,
    dto: PlannedUnitDTO,
    attachments?: any[]
  ): Observable<PlannedUnitDTO> {
    dto.id = id;
    let formData: FormData = new FormData();
    formData.append("planned_unit", JSON.stringify(dto));
    if (attachments) {
      attachments.forEach((attachment, index) => {
        formData.append(`attachment_${index}`, attachment);
      });
    }
    return this.httpClient.post<PlannedUnitDTO>(this.ROUTES.store, formData); //mrosetti - Using PUT instead of POST will break the entire request, don't do it
  }

  public deletePlannedUnit(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public generateSummary(id: number): Observable<Blob> {
    let params = {
      id: "" + id
    };
    return this.httpClient.get(this.ROUTES.summary, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }
}
