import { reducers } from './../../../../_store/index';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import * as UserActions from 'src/app/_store/user.actions';
import { State } from 'src/app/_store';
import { User } from 'src/app/_models/user';
import { AlertService } from 'src/app/_services/alert.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  passwordForm: FormGroup;

  user: User;
  constructor(private store$: Store<State>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    if (this.data && this.data.user) {
      this.user = new User(this.data.user);
    }
    this._createForm();
  }

  private _createForm() {
    let group = {
      newPass: ["", Validators.required],
      confirmPass: ["", Validators.required]
    };

    this.passwordForm = this.fb.group(group, {
      validator: this.checkIfMatchingPasswords("newPass", "confirmPass")
    });
  }

  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  private ngOnChanges() {
    if (this.passwordForm) {
      this.passwordForm.reset();
    }
  }

  updatePassword() {
    this.store$.dispatch(UserActions.updatePassword({ newPassword: this.passwordForm.get('newPass').value, user: this.user ? this.user : null }));
  }

  close() {
    if (this.passwordForm.pristine) {
      this.store$.dispatch(UserActions.closeChangePasswordDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.doClose();
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }

  private doClose() {
    this.store$.dispatch(UserActions.closeChangePasswordDialog());
  }
}








