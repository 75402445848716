import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Inject, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { dateFromParts, extractDay, extractMonth } from 'src/app/_helpers/utils';
import { Subject } from 'rxjs';

class CustomFieldErrorMatcher implements ErrorStateMatcher {
  constructor(private customControl: FormControl) { }
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && this.customControl.invalid;
  }
}

@Component({
  selector: 'app-date-month-field',
  templateUrl: 'date-month-field.component.html',
  styleUrls: ['date-month-field.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: DateMonthFieldComponent }],
  host: {
    '[class.date-month-floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[required]': 'required',
    '[attr.aria-invalid]': '(empty && required) ? null : errorState',
    '[attr.aria-required]': 'required',
  },
})
export class DateMonthFieldComponent implements ControlValueAccessor, MatFormFieldControl<Date>, OnDestroy {
  static nextId = 0;
  @ViewChild('month') monthInput: HTMLInputElement;
  @ViewChild('day') dayInput: HTMLInputElement;

  DAYS: string[] = Array.from(Array(31), (_, i) => `${i + 1}`);
  parts = this.fb.group({
    month: ['', [Validators.required, Validators.min(1), Validators.max(12)]],
    day: ['', [Validators.required, Validators.min(1), Validators.max(31)]],
  });
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'date-month-input';
  id = `date-month-input-${DateMonthFieldComponent.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };

  errorMatcher() {
    return new CustomFieldErrorMatcher(this.ngControl.control as FormControl)
  }

  get empty() {
    const {
      value: { month, day },
    } = this.parts;

    return !month && !day;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Date | null {
    if (this.parts.valid) {
      const {
        value: { month, day },
      } = this.parts;
      return (month && day) ? dateFromParts(2022, Number(month), Number(day)) : null;
    }
    return null;
  }

  set value(date: Date | null) {
    this.parts.reset();
    if (date) {
      const day = `${extractDay(date)}`;
      const month = `${extractMonth(date)}`;
      console.log('Day and month', day, month);
      this.parts.setValue({ month, day });
    }

    this.stateChanges.next();
  }

  get errorState(): boolean {
    return !this.parts.valid;
  }

  constructor(
    private fb: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.parts.valueChanges.subscribe(() => {
      this.onChange(this.value);
    })
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.date-month-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (this.parts.controls.day.valid) {
      this._focusMonitor.focusVia(this.dayInput, 'program');
    } else if (this.parts.controls.month.valid) {
      this._focusMonitor.focusVia(this.dayInput, 'program');
    } else {
      this._focusMonitor.focusVia(this.monthInput, 'program');
    }
  }

  writeValue(date: Date | null): void {
    this.value = date;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
