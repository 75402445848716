import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../_helpers/listResult.interface';
import { Org } from '../_models/org';
import { Employee, EmployeeFilter } from './../_models/employee';
import { LaravelEmployeeService } from './laravel/laravel-employee.service';

@Injectable({ providedIn: "root" })
export class EmployeeService {
  constructor(private laravelEmployeeService: LaravelEmployeeService) {}

  public addEmployee(employee: Employee): Observable<Employee> {
    return this.laravelEmployeeService
      .createEmployee(
        employee.toDTO(),
        employee.attachments.map((a) => a.file)
      )
      .pipe(
        map((dto) => {
          return new Employee(dto);
        })
      );
  }

  public updateEmployee(employee: Employee): Observable<Employee> {
    return this.laravelEmployeeService
      .updateEmployee(
        employee.objectId,
        employee.toDTO(),
        employee.isRelationLoaded("attachments")
          ? employee.attachments.map((a) => a.file)
          : null
      )
      .pipe(
        map((dto) => {
          return new Employee(dto);
        })
      );
  }

  public archiveEmployee(employee: Employee): Observable<Employee> {
    return this.laravelEmployeeService.deleteEmployee(employee.objectId).pipe(
      map(() => {
        return employee;
      })
    );
  }

  public getEmployee(
    employeeId: number,
    include?: string | string[]
  ): Observable<Employee> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelEmployeeService
      .getEmployeeById(employeeId, includes)
      .pipe(map((dto) => new Employee(dto)));
  }

  public changeOrg(employeeId: number, orgId: number): Observable<Employee> {
    return this.laravelEmployeeService
      .changeOrg(employeeId, orgId)
      .pipe(map((dto) => new Employee(dto)));
  }

  public getEmployees(
    org: Org,
    onlyPrivate: boolean,
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter?: EmployeeFilter,
    include?: string | string[]
  ): Observable<ListResult<Employee>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelEmployeeService
      .getEmployees(
        org ? org.objectId : null,
        onlyPrivate,
        page,
        perPage,
        order,
        direction,
        filter,
        includes
      )
      .pipe(
        map((response) => {
          return {
            data: response.data.map((dto) => new Employee(dto)),
            total: response.total,
          };
        })
      );
  }

  public identifierExists(
    employeeId: number,
    identifier: string
  ): Observable<boolean> {
    return this.laravelEmployeeService.checkIdentifierExists(
      employeeId,
      identifier
    );
  }

  public restoreEmployee(employee: Employee): Observable<Employee> {
    return this.laravelEmployeeService.restoreOrg(employee.objectId).pipe(
      map((restored) => {
        return new Employee(restored);
      })
    );
  }

  public exportStatus(employeeId: number): Observable<Blob> {
    return this.laravelEmployeeService
      .exportStatus(employeeId)
      .pipe(map((data) => new Blob([data], { type: "text/csv" })));
  }
}
