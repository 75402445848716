import { User } from "../_models/user";
import * as fromApp from "./app.actions";
import { Service } from "../_models/service";

export interface AppState {
  currentUser: User;
  services: Service[];
  currentPageTitle: string;
  currentPageSlug: string;
}

export const initialState: AppState = {
  currentUser: null,
  services: null,
  currentPageTitle: "Scadenziario - 2.0",
  currentPageSlug: null
};

export function clearState(reducer) {
  return function (state, action) {
    if (action.type === fromApp.ActionTypes.SignOut) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export function reducer(
  state = initialState,
  action: fromApp.ActionsUnion
): AppState {
  switch (action.type) {
    case fromApp.ActionTypes.SignIn:
    case fromApp.ActionTypes.SetCurrentUser: {
      return {
        ...state,
        currentUser: action.payload.user
      };
    }
    case fromApp.ActionTypes.SignOut: {
      return {
        ...state,
        currentUser: null
      };
    }
    case fromApp.ActionTypes.LoadServices: {
      return {
        ...state,
        services: action.payload.services
      };
    }
    case fromApp.ActionTypes.CurrentPage: {
      return {
        ...state,
        currentPageTitle: action.payload.title,
        currentPageSlug: action.payload.slug
      };
    }

    default: {
      return state;
    }
  }
}

export const currentUser = (state: AppState) => state.currentUser;
export const services = (state: AppState) => state.services;
export const currentPageTitle = (state: AppState) => state.currentPageTitle;
export const currentPageSlug = (state: AppState) => state.currentPageSlug;
