import { createAction, props } from '@ngrx/store';
import { BillingLine, BillingLineDTO } from 'src/app/_models/billing-line.model';
import { Invoice, InvoiceDTO, InvoiceFilters } from 'src/app/_models/invoice';




export const loadCurrentInvoice = createAction('[Invoices] Load current invoice',);
export const loadCurrentInvoiceCompleted = createAction('[Invoices] Load current invoice completed', props<{ currentInvoice: InvoiceDTO }>());
export const loadCurrentInvoiceFailed = createAction('[Invoices] Load current invoice failed', props<{ error: any }>());

export const loadInvoice = createAction('[Invoices] Load  invoice', props<{ id: number }>());
export const loadInvoiceCompleted = createAction('[Invoices] Load  invoice completed', props<{ invoice: InvoiceDTO }>());
export const loadInvoiceFailed = createAction('[Invoices] Load  invoice failed', props<{ error: any }>());

export const loadInvoices = createAction('[Invoices] Load Invoices', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: InvoiceFilters, includes?: string[] }>());
export const loadInvoicesCompleted = createAction('[Invoices] Load Invoices Completed', props<{ invoices: InvoiceDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: Partial<InvoiceFilters>, includes?: string[] }>());
export const loadInvoicesFailed = createAction('[Invoices] Load Invoices Failed', props<{ error: any }>());

export const changePage = createAction('[Invoices] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Invoices] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Invoices] Change filters', props<{ filters: InvoiceFilters }>());

export const editInvoice = createAction('[Invoices] Edit invoice', props<{ invoice: Invoice }>());
export const editCancelInvoice = createAction('[Invoices] Edit cancel invoice', props<{ invoice: Invoice }>());
export const invoiceDialogOpened = createAction('[Invoices] Detail dialog opened', props<{ dialogId: string }>());
export const closeInvoiceDialog = createAction('[Invoices] Close detail dialog', props<{ dialogId?: string }>());

export const saveInvoice = createAction('[Invoices] Save invoice', props<{ invoice: Invoice }>());
export const saveInvoiceCompleted = createAction('[Invoices] Save invoice completed', props<{ invoice: InvoiceDTO }>());
export const saveInvoiceFailed = createAction('[Invoices] Save invoice failed', props<{ error: any }>());

export const cancelInvoice = createAction('[Invoices] Cancel invoice', props<{ invoice: InvoiceDTO }>());
export const cancelInvoiceCompleted = createAction('[Invoices] Cancel invoice completed', props<{ invoice: InvoiceDTO }>());
export const cancelInvoiceFailed = createAction('[Invoices] Cancer invoice failed', props<{ error: any }>());


export const selectInvoice = createAction('[Invoices] Select invoice', props<{ filters?: Partial<InvoiceFilters> }>());
export const selectionDialogOpened = createAction('[Invoices] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Invoices] Close selection dialog');
export const invoiceSelected = createAction('[Invoices] Invoice selected', props<{ invoice: InvoiceDTO }>());

export const confirmNewInvoice = createAction('[Invoices] Confirm invoice generation');
export const newInvoice = createAction('[Invoices] New invoice', props<{ mode: string }>());
export const newInvoiceCompleted = createAction('[Invoices] New invoice completed', props<{ invoices: InvoiceDTO[] }>());
export const newInvoiceFailed = createAction('[Invoices] New invoice failed', props<{ error: any }>());

export const checkInvoiceCode = createAction('[Invoices] Check invoice code', props<{ invoiceId: number, code: number }>());
export const checkInvoiceCodeCompleted = createAction('[Invoices] Check invoice code completed', props<{ response: boolean }>());
export const checkInvoiceCodeFailed = createAction('[Invoices] Check invoice code failed', props<{ error: any }>());

export const detachBillingLine = createAction('[Invoices] Detach billing line', props<{ billingLine: BillingLineDTO }>());
export const detachBillingLineCompleted = createAction('[Invoices] Detach billing line completed', props<{ billingLine: BillingLineDTO }>());
export const detachBillingLineFailed = createAction('[Invoices] Detach billing lineinvoice failed', props<{ error: any }>());

export const invoiceXML= createAction("[Invoices] invoice XML", props<{  invoiceId: number }>());
export const invoiceXMLCompleted = createAction("[Invoices] invoice  XML completed",props<{ blob: Blob }>());
export const invoiceXMLFailed = createAction("[Invoices] invoice  XML failed",props<{ error: any }>());

export const invoicesXML= createAction("[Invoices] invoices XML", props<{  ids: number[] }>());
export const invoicesXMLCompleted = createAction("[Invoices] invoices  XML completed",props<{ blob: Blob }>());
export const invoicesXMLFailed = createAction("[Invoices] invoices  XML failed",props<{ error: any }>());

export const invoicePDF= createAction("[Invoices] invoice PDF", props<{  invoiceId: number }>());
export const invoicePDFCompleted = createAction("[Invoices] invoice PDF completed",props<{ blob: Blob }>());
export const invoicePDFFailed = createAction("[Invoices] invoice PDF failed",props<{ error: any }>());

export const addSelectInvoices = createAction('[Invoices] Add selected invoices', props<{ invoice: Invoice }>());
export const removeSelectedInvoices = createAction('[Invoices] Remove selected invoices', props<{ invoice: Invoice }>());
export const resetSelectedInvoices = createAction('[Invoices] Reset selected invoices');




