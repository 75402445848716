import { Action, createReducer, on } from '@ngrx/store';
import { ProjectDTO, ProjectFilters } from 'src/app/_models/project';

import { ProjectArea } from '../../../_models/project';
import * as ProjectActions from '../actions/project.actions';

export interface ProjectState {
  list: ProjectDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: Partial<ProjectFilters>,
  dialogId: string,
  selectionDialogId: string,
  currentProject: ProjectDTO,
  projectId: number,
  nextProgressive: {
    projectId: number,
    area: ProjectArea,
    progressive: number
  }
};

const initialState: ProjectState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  projectId: null,
  currentProject: null,
  nextProgressive: {
    projectId: null,
    area: null,
    progressive: null
  }
};

const projectReducer = createReducer(
  initialState,
  on(ProjectActions.loadProjectsCompleted, (state, { projects, currentPage, total, perPage, order, direction, includes }) => {
    return { ...state, list: projects, currentPage, total, perPage, order, direction, includes };
  }),
  on(ProjectActions.projectDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ProjectActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ProjectActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(ProjectActions.showProject, (state, { currentProject }) => {
    return { ...state, currentProject };
  }),
  on(ProjectActions.addProject, (state) => {
    return { ...state, currentProject: null };
  }),
  on(ProjectActions.loadCurrentProjectCompleted, (state, { currentProject }) => {
    return { ...state, currentProject };
  }),
  on(ProjectActions.loadProjectCompleted, (state, { project }) => {
    return { ...state, currentProject: project };
  }),
  on(ProjectActions.nextProgressiveCompleted, (state, { progressive, area, id }) => {
    return { ...state, nextProgressive: { progressive, area, projectId: id } };
  }),

);

export function reducer(state: ProjectState | undefined, action: Action) {
  return projectReducer(state, action);
}
