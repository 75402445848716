import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ListResultDTO } from "../../_helpers/listResult.interface";
import { environment } from "./../../../environments/environment";
import { ServiceDTO } from "./../../_models/service";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class LaravelServicesService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/service",
      list: environment["laravel"]["serverUrl"] + "/api/services",
      store: environment["laravel"]["serverUrl"] + "/api/service",
      delete: environment["laravel"]["serverUrl"] + "/api/service",
      by_units: environment["laravel"]["serverUrl"] + "/api/service/by_units"
    };
  }

  getServiceById(id: number, include?: string[]): Observable<any> {
    let params = {
      id: "" + id
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public getServices(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: string,
    include?: string[]
  ): Observable<ListResultDTO<ServiceDTO>> {
    if (per_page) {
      return this.getPaginatedResults(
        page,
        per_page,
        order,
        direction,
        filter,
        include
      );
    } else {
      return this.getAllResults(order, direction, filter, include).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  private getPaginatedResults(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: string,
    include?: string[]
  ): Observable<ListResultDTO<ServiceDTO>> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (filter) params["filter"] = "" + filter;
    if (include) params["include[]"] = include;

    return this.httpClient.get<ListResultDTO<ServiceDTO>>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  private getAllResults(
    order: string,
    direction: string,
    filter?: string,
    include?: string[]
  ): Observable<ServiceDTO[]> {
    let params = {};

    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (filter) params["filter"] = "" + filter;
    if (include) params["include[]"] = include;

    return this.httpClient.get<ServiceDTO[]>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public createService(dto: ServiceDTO): Observable<ServiceDTO> {
    return this.httpClient.post<ServiceDTO>(this.ROUTES.store, {
      service: dto
    });
  }

  public updateService(id: number, dto: ServiceDTO): Observable<ServiceDTO> {
    dto.id = id;
    return this.httpClient.put<ServiceDTO>(this.ROUTES.store, { service: dto });
  }

  public archiveService(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public getByUnits(unit_ids: number[]): Observable<ServiceDTO[]> {
    let params = {};
    if (unit_ids) params["unit_ids[]"] = unit_ids;
    return this.httpClient.get<ServiceDTO[]>(this.ROUTES.by_units, {
      params: new HttpParams({ fromObject: params })
    });
  }
}
