import { DataSource } from '@angular/cdk/table';
import { User, UserFilters } from 'src/app/_models/user';
import { UserService } from 'src/app/_services/user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export class UsersDataSource implements DataSource<User> {
  private userSubject = new BehaviorSubject<User[]>([]);

  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.totalSubject.asObservable();

  constructor(private userService: UserService) { }

  connect(): Observable<User[]> {
    return this.userSubject.asObservable();
  }

  disconnect(): void {
    this.userSubject.complete();
    this.loadingSubject.complete();
    this.totalSubject.complete();
  }

  loadUsers(
    page: number = 0,
    perPage: number = 25,
    order: string = "name",
    direction: string = "",
    filter?: string,
    filters?: UserFilters,
    include?: string | string[]
  ) {
    this.loadingSubject.next(true);

    this.userService
      .getUsers(page, perPage, order, direction, filter, filters, include)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        result => {
          this.totalSubject.next(result.total);
          this.userSubject.next(result.data);
        },
        error => {
          console.error("Error", error);
          this.totalSubject.next(0);
          this.userSubject.next([]);
        }
      );
  }
}
