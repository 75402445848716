<mat-chip-list #chipList>
  <mat-chip color="primary" *ngFor="let user of value; let i = index;" [removable]="true" [selectable]="false"
    (removed)="removeUser(i)">
    {{user.name}} {{user.surname}}
    <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
  </mat-chip>
  <button type="button" style="margin: 5px" matTooltip="Aggiungi Utente" (click)="selectUser()" mat-icon-button
    mat-raised-button *ngIf="!disabled">
    <mat-icon>add</mat-icon>
  </button>
</mat-chip-list>
