<div class="attachment-upload">
  <div *ngIf="fileUrl || file">
    <a style="display: none" #downloadLink></a>
    <button mat-button color="primary" *ngIf="attachment" type="button" matTooltip="Scarica file"
      (click)="downloadFile()">
      {{attachment.filename}}
    </button>
    <span *ngIf="file">
      {{file.name}}
    </span>
    <button mat-icon-button type="button" (click)="attachmentInput.click()" matTooltip="Modifica">
      <mat-icon class="sidenav-icon">edit</mat-icon>
    </button>
  </div>
  <input #attachmentInput type="file" style="display: none" (change)="onFileChange($event)">
  <button *ngIf="!fileUrl && !file" mat-raised-button type="button" (click)="attachmentInput.click()">Allega
  </button>
</div>
