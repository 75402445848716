
import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO, DateString } from './base';
import { formatDateForBackend } from 'src/app/_helpers/utils';

import { OrgDTO, Org } from './org';
import { ArticleDTO, Article } from './article';
import { ActivityDTO, Activity } from './activity';
import { Project, ProjectDTO } from './project';
import { Invoice, InvoiceDTO } from './invoice';
import { Employee, EmployeeDTO } from './employee';

export interface BillingLineFilters {
    projectId: number;
    org?: Org;
    from?: Date;
    to?: Date;
    identifier?: number;
    toBill?: boolean;
}

export interface BillingLineDTO extends BaseDTO {
    date: DateString;
    client_id: number;
    description: string;
    article_id: number;
    amount: number;
    activity_id: number;
    client: EmployeeDTO;
    article: ArticleDTO;
    activity: ActivityDTO;
    project_id: number;
    project: ProjectDTO;
    invoice_id: number;
    invoice: InvoiceDTO;
}

export class BillingLine extends Base {
    date?: Date;
    clientId: number;
    description: string;
    articleId: number;
    amount: number;
    activityId: number;
    client?: Employee;
    article?: Article;
    activity?: Activity;
    projectId: number;
    project: Project;
    invoice: Invoice;

    constructor(source: BillingLineDTO) {
        super(source);
        if (source) {
            this.date = source.date ? new Date(source.date) : undefined
            this.clientId = source.client_id
            this.description = source.description
            this.articleId = source.article_id
            this.amount = source.amount
            this.activityId = source.activity_id
            this.client = source.client ? new Employee(source.client) : undefined;
            this.article = source.article ? new Article(source.article) : undefined;
            this.activity = source.activity ? new Activity(source.activity) : undefined;
            this.project = source.project ? new Project(source.project) : undefined;
            this.projectId = source.project_id;
            if (source.invoice) {
                this.invoice = new Invoice(source.invoice);
                this.addLoadedRelation('invoice');
              }
        }
    }

    toDTO(): BillingLineDTO {
        let result: BillingLineDTO = <BillingLineDTO>super.toDTO();
        result.date = formatDateForBackend(this.date);
        result.client_id = this.clientId;
        result.description = this.description;
        result.article_id = this.articleId;
        result.amount = this.amount;
        result.activity_id = this.activityId;
        result.client = this.client?.toDTO();
        result.article = this.article?.toDTO();
        result.activity = this.activity?.toDTO();
        result.project = this.project?.toDTO();
        result.project_id = this.projectId;
        result.invoice = this.invoice ? this.invoice.toDTO() : null;
        result.invoice_id = this.invoice ? this.invoice.objectId: null;

        return result;
    }

    static fromFormGroup(formGroup: AbstractControl, original?: BillingLine) {
        const formModel = formGroup.value;
        let billingLine: BillingLine = new BillingLine(null);

        billingLine.date = formModel.date;
        billingLine.clientId = formModel.client?.objectId;
        billingLine.description = formModel.description;
        billingLine.articleId = formModel.article?.objectId;
        billingLine.amount = formModel.amount;
        billingLine.activityId = formModel.activity?.objectId;
        billingLine.client = formModel.client;
        billingLine.article = formModel.article;
        billingLine.activity = formModel.activity;
        billingLine.projectId = formModel.projectId;
        billingLine.invoice = formModel.invoice;
        billingLine.objectId = formModel.objectId;

        if (original) {
            billingLine.objectId = original.objectId;
        }
        return billingLine;
    }

    get stringRepresentation(): string {
        return this.objectId?.toString();
    }
}
