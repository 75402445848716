import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, forwardRef, HostBinding, Inject, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgControl, NgForm, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { State } from 'src/app/_store';
import { Time } from '@angular/common';
import { range } from 'src/app/_helpers/utils';
import { ErrorStateMatcher } from '@angular/material/core';


class CustomFieldErrorMatcher implements ErrorStateMatcher {
  constructor(private customControl: FormControl) { }
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && this.customControl.invalid;
  }
}

export const USERS_FIELD_MAT_FORM_FIELD_CONTROL = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => TimePickerComponent),
};

@Component({
  selector: "time-picker",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.scss"],
  providers: [USERS_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class TimePickerComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<Time> {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  private unsubscribe$ = new Subject<void>();

  private _disabled = false;
  private _required = false;

  focused: boolean;
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  currentFormControlName: string;

  @Input()
  gapMinutes: number = 1;
  @Input()
  min: Time = { hours: 0, minutes: 0 };
  @Input()
  max: Time = { hours: 23, minutes: 59 };

  hours: number[];
  minutes: number[];

  form: FormGroup = this.fb.group({
    hours: new FormControl<number>(null, [Validators.required]),
    minutes: new FormControl<number>(null, [Validators.required]),
  });

  errorMatcher() {
    return new CustomFieldErrorMatcher(this.ngControl.control as FormControl)
  }

  get errorState(): boolean {
    return !this.form.valid;
  }

  constructor(
    private store$: Store<State>,
    private fb: FormBuilder,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  controlType?: string;

  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  onContainerClick(event: MouseEvent): void {
  }

  ngOnInit(): void {
    this.hours = [...range(0, 23, 1)];
    this.minutes = [...range(0, 59, this.gapMinutes)];
  }

  ngOnChanges(): void {
    if (this.max) {
      this.form.get('hours').setValidators([Validators.required, Validators.max(this.max.hours)])

      this.form.get('hours').valueChanges.subscribe((value) => {
        if (value === this.max.hours) {
          this.form.get('minutes').setValidators([Validators.required, Validators.max(this.max.minutes)]);
        } else if (value > this.max.hours) {
          this.form.get('minutes').setValidators([Validators.required, Validators.max(0)]);
        } else {
          this.form.get('minutes').setValidators([Validators.required]);
        }
        this.form.get('minutes').updateValueAndValidity();
      })
    } else {
      this.form.get('hours').setValidators([Validators.required]);
    }

    if (this.min) {
      this.form.get('hours').setValidators([Validators.required, Validators.min(this.min.hours)])

      this.form.get('hours').valueChanges.subscribe((value) => {
        if (value === this.min.hours) {
          this.form.get('minutes').setValidators([Validators.required, Validators.min(this.min.minutes)]);
        } else if (value < this.min.hours) {
          this.form.get('minutes').setValidators([Validators.required, Validators.max(-1)]);
        } else {
          this.form.get('minutes').setValidators([Validators.required]);
        }
        this.form.get('minutes').updateValueAndValidity();
      })
    } else {
      this.form.get('hours').setValidators([Validators.required]);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  clear() {
    this.value = null;
  }

  @Input()
  set value(time: Time) {
    if (time) {
      if(time.hours === undefined){
        time.hours = null
      }
      if(time.minutes === undefined){
        time.minutes = null
      }
      this.form.setValue({ hours: time.hours, minutes: time.minutes  });
      this.notifyValueChange();
    }
    this.stateChanges.next();
  }

  get value(): Time {
    const { hours, minutes } = this.form.value;
    return { hours, minutes };
  }

  onChange: (value: Time) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  setDescribedByIds(ids: string[]): void {
  }

  writeValue(obj: Time): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

    this.form.valueChanges.subscribe(() => {
      this.onChange(this.value);
    })
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
