import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertService } from '../_services/alert.service';
import { UserService } from '../_services/user.service';

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  hide = true;
  returnUrl: string;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private userService: UserService
  ) { }

  ngOnInit() {
    // reset login status
    this.userService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";

    this.loginForm = this.fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  login() {
    if (this.loginForm.valid) {
      this.userService
        .login(this.loginForm.value.username, this.loginForm.value.password)
        .subscribe(
          () => {
            this.alertService.showConfirmMessage('Login effettuato con successo');
            this.router.navigate([this.returnUrl]);
          },
          error => {
            this.alertService.showErrorMessage(
              error.status === 401
                ? "E-mail/password non validi"
                : error.message,
              null
            );
          }
        );
    }
  }
}
