<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Generazione fatture</span>
  </span>
</header>
<mat-dialog-content>

  <p>
    <strong>{{selectedBillinglines?.length}}</strong> rig<span *ngIf="selectedBillinglines?.length == 1">a</span><span *ngIf="selectedBillinglines?.length > 1">he</span>  di fatturazione selezionate
  </p>


  <p *ngIf="selectedBillinglines?.length > 1">
    <mat-form-field class="u-full-width">
      <mat-label>Seleziona modalità di generazione</mat-label>
      <mat-select [formControl]="invoiceModeCtrl" >
        <mat-option *ngFor="let group of invoiceModes" [value]="group">
          <span *ngIf="group == 'Fattura unica'"> Fattura unica (per cliente)</span>
          <span *ngIf="group == 'Fatture multiple'"> Fatture multiple (una fattura per ogni rata)</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </p>

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
    <button mat-raised-button color="basic" type="button" (click)="close()">Annulla</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="!invoiceModeCtrl.value && selectedBillinglines?.length > 1">Genera fattura</button>
</mat-dialog-actions>
