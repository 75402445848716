import { AbstractControl } from "@angular/forms";
import { Activity, ActivityDTO } from "./activity";
import { Base, BaseDTO } from "./base";
import { Project, ProjectDTO } from "./project";




export interface QuestionDTO extends BaseDTO {
    activity_id: number;
    activity: ActivityDTO;
    comment: string;
    progressive: string;
    created_at: Date;
}


export class Question extends Base {
    activityId: number;
    activity: Activity;
    comment: string;
    progressive: string;
    createdAt: Date;


constructor(dto: QuestionDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
          if (dto) {
              if (dto.activity) {
                  this.activity = new Activity(dto.activity);
                  this.addLoadedRelation("activity");
              }
                this.activityId = dto.activity_id;
                this.comment = dto.comment;
                this.progressive = dto.progressive;
                this.createdAt = dto.created_at;
    }
    }

    public toDTO(): QuestionDTO {
    const result: QuestionDTO = <QuestionDTO>super.toDTO()
    result.activity = this.activity ? this.activity.toDTO() : null;
    result.activity_id = this.activity?.objectId;
    result.comment = this.comment;
    result.progressive = this.progressive;
    result.created_at = this.createdAt;

    return result;
    }

    static fromFormGroup(formGroup: AbstractControl, original?: Question): Question {
    const formModel = formGroup.getRawValue();

    let question: Question = new Question(null);
    question.activity = formModel.activity;
    question.activityId = formModel.activity?.objectId;
    question.comment = formModel.comment;
    question.progressive = formModel.progressive;
    question.createdAt = formModel.createdAt;


    if (original) {
      question.objectId = original.objectId;
      question.loadedRelations = original.loadedRelations;
    }

    console.log(question);

    return question;
  }


}
