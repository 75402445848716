<header mat-dialog-title>
  Scegli azienda
</header>
<mat-dialog-content style="width: 80vw">
  <div class="mat-elevation-z2">
    <mat-toolbar>
      <form (onSubmit)="reload()" novalidate (keyup.enter)="reload()">
        <span>
          <input matInput type="text" placeholder="Cerca" [(ngModel)]="queryString" name="queryString" />
          <button type="button" mat-icon-button color="primary">
            <mat-icon aria-label="Start search button" (click)="reload()">search</mat-icon>
          </button>
          <button type="button" mat-icon-button color="primary" *ngIf="queryString">
            <mat-icon aria-label="Clear search button" (click)="queryString = null; reload()">close</mat-icon>
          </button>
        </span>
      </form>
    </mat-toolbar>
    <div *ngIf="!(dataSource.loading$ | async) && (dataSource.total$ | async) == 0" layout-fill layout="column">
      <mat-card class="text-center" layout="row" layout-align="center">
        <h4>Nessuna azienda presente</h4>
      </mat-card>
    </div>
    <div [hidden]="(dataSource.loading$ | async) || (dataSource.total$ | async) == 0">
      <mat-table #table [dataSource]="dataSource" class="table" matSort matSortActive="created_at" matSortDisableClear
        matSortDirection="asc">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header="name">Ragione sociale</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
        </ng-container>

        <!-- VAT Column -->
        <ng-container matColumnDef="VAT">
          <mat-header-cell *matHeaderCellDef mat-sort-header="VAT">P.IVA</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.VAT }}</mat-cell>
        </ng-container>

        <!-- City Column -->
        <ng-container matColumnDef="city">
          <mat-header-cell *matHeaderCellDef>Città</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row.address">{{row.address.city}}
              <mat-icon *ngIf="row.address.note" #tooltip="matTooltip" [matTooltip]="row.address.note">chat</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <!-- Contact Column -->
        <ng-container matColumnDef="contact">
          <mat-header-cell *matHeaderCellDef>Referente Formazione</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row.firstContact">{{row.firstContact.name}} {{row.firstContact.surname}}
              <mat-icon *ngIf="row.firstContact.note" #tooltip="matTooltip" [matTooltip]="row.firstContact.note">chat
              </mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-raised-button color="primary" (click)="selectOrg(row)">Seleziona</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <mat-paginator [length]="(dataSource.total$ | async)" [pageSize]="25" [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="basic" (click)="close()">Chiudi</button>
</mat-dialog-actions>