<div [formGroup]="form">
  <table mat-table [dataSource]="attachmentsDatasource" formArrayName="attachments">
    <tr mat-header-row *matHeaderRowDef="attachmentsDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: attachmentsDisplayedColumns"></tr>

    <ng-container matColumnDef="file">
      <th mat-header-cell *matHeaderCellDef>
        File
      </th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        <app-attachment-upload [attachment]="attachments?.length ? attachments[index] : null" formControlName="file">
        </app-attachment-upload>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Tipologia</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        <mat-form-field floatLabel="never" class="full-width-input">
          <input *ngIf="!types" type="text" placeholder="Tipologia" matInput formControlName="type" required />
          <mat-select *ngIf="types" placeholder="Tipologia" matInput formControlName="type" required>
            <mat-option *ngFor="let documentType of types" [value]="documentType">{{documentType}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Descrizione</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        <mat-form-field floatLabel="never" class="full-width-input">
          <input type="text" placeholder="Descrizione" matInput formControlName="description" />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="add">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button color="primary" (click)="addAttachment()" [disabled]="!form.valid">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <button mat-icon-button color="danger" type="warn" (click)="removeAttachment(i)">
          <mat-icon aria-label="Delete attachment button">delete</mat-icon>
        </button>
      </td>
    </ng-container>

  </table>
</div>
