import { createAction, props } from '@ngrx/store';
import { ProjectDTO, ProjectFilters } from 'src/app/_models/project';

import { ProjectArea } from '../../../_models/project';

export const addProject = createAction('[Projects] Add project');
export const showProject = createAction('[Projects] Show project', props<{ currentProject: ProjectDTO }>());

export const loadCurrentProject = createAction('[Projects] Load current project',);
export const loadCurrentProjectCompleted = createAction('[Projects] Load current project completed', props<{ currentProject: ProjectDTO }>());
export const loadCurrentProjectFailed = createAction('[Projects] Load current project failed', props<{ error: any }>());

export const loadProject = createAction('[Projects] Load  project', props<{ id: number }>());
export const loadProjectCompleted = createAction('[Projects] Load  project completed', props<{ project: ProjectDTO }>());
export const loadProjectFailed = createAction('[Projects] Load  project failed', props<{ error: any }>());

export const loadProjects = createAction('[Projects] Load projects', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: Partial<ProjectFilters>, includes?: string[] }>());
export const loadProjectsCompleted = createAction('[Projects] Load projects Completed', props<{ projects: ProjectDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: Partial<ProjectFilters>, includes?: string[] }>());
export const loadProjectsFailed = createAction('[Projects] Load projects Failed', props<{ error: any }>());

export const changePage = createAction('[Projects] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Projects] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Projects] Change filters', props<{ filters: Partial<ProjectFilters> }>());

export const editProject = createAction('[Projects] Edit project', props<{ project: ProjectDTO }>());
export const projectDialogOpened = createAction('[Projects] Detail dialog opened', props<{ dialogId: string }>());
export const closeProjectDialog = createAction('[Projects] Close detail dialog');

export const saveProject = createAction('[Projects] Save project', props<{ project: ProjectDTO }>());
export const saveProjectCompleted = createAction('[Projects] Save project completed', props<{ project: ProjectDTO }>());
export const saveProjectFailed = createAction('[Projects] Save project failed', props<{ error: any }>());

export const deleteProject = createAction('[Projects] Delete project', props<{ project: ProjectDTO }>());
export const deleteProjectCompleted = createAction('[Projects] Delete project completed', props<{ project: ProjectDTO }>());
export const deleteProjectFailed = createAction('[Projects] Delete project failed', props<{ error: any }>());

export const duplicateProject = createAction('[Projects] Duplicate project', props<{ project: ProjectDTO }>());
export const duplicateProjectCompleted = createAction('[Projects] Duplicate project completed', props<{ project: ProjectDTO }>());
export const duplicateProjectFailed = createAction('[Projects] Duplicate project failed', props<{ error: any }>());

export const selectProject = createAction('[Projects] Select project', props<{ filters?: Partial<ProjectFilters> }>());
export const selectionDialogOpened = createAction('[Projects] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Projects] Close selection dialog');
export const projectSelected = createAction('[Projects] Project selected', props<{ project: ProjectDTO }>());

export const nextProgressive = createAction('[Projects] Next progressive', props<{ area: ProjectArea, id: number }>());
export const nextProgressiveCompleted = createAction('[Projects] Next progressive completed', props<{ progressive: number, area: ProjectArea, id: number }>());
export const nextProgressiveFailed = createAction('[Projects] Next progressive failed', props<{ error: any }>());