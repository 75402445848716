<header mat-dialog-title>
  Invia e-mail a {{recipients.length}} destinatari
</header>
<mat-dialog-content>
  <form [formGroup]="mailForm" *ngIf="recipients.length > 0" novalidate>
    <table style="width: 100%">
      <tr>
        <td style="width: 70%">
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Oggetto" type="text" formControlName="subject" required>
            <mat-error *ngIf="mailForm.controls['subject'].errors">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </td>

        <td style="width: 30%">
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Mittente" type="email" formControlName="sender" required>
          </mat-form-field>
        </td>
      </tr>
    </table>
    <div style="padding: 5px">
      <label>Testo della mail</label>
      <ngx-wig formControlName="template">
      </ngx-wig>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="basic" mat-dialog-close>Annulla</button>
  <button mat-raised-button *ngIf="recipients.length > 0" color="primary" type="button" (click)="send()"
    [disabled]="!mailForm.valid">Conferma</button>
</mat-dialog-actions>