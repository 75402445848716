import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatDateForBackend } from 'src/app/_helpers/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { BillingLineDTO } from 'src/app/_models/billing-line.model';
import { ParticipantDTO } from 'src/app/_models/planning';

@Injectable({ providedIn: "root" })
export class LaravelParticipantService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      generate_certificate:
        environment["laravel"]["serverUrl"] +
        "/api/participant/generate_certificate",
      is_busy:
        environment["laravel"]["serverUrl"] +
        "/api/participant/is_busy",
      generate_multiple_certificates:
        environment["laravel"]["serverUrl"] +
        "/api/participant/generate_multiple_certificates",
        generateBillingLine:
        environment["laravel"]["serverUrl"] + "/api/participant/generate_billing_line",  
        generateMultipleBillingLine:
        environment["laravel"]["serverUrl"] + "/api/participant/generate_multiple_billing_lines", 
    };
  }

  private _parseFilenameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) return null;
    let matches = /filename="(.*?)"/g.exec(contentDisposition);

    return matches && matches.length > 1 ? matches[1] : null;
  }

  public generateCertificate(id: number): Observable<{ data: Blob, filename?: string }> {
    let params = {
      id: "" + id
    };
    return this.httpClient.get(this.ROUTES.generate_certificate, {
      params: new HttpParams({
        fromObject: params
      }),
      observe: 'response',
      responseType: "blob"
    }).pipe(map(response => {

      return {
        filename: this._parseFilenameFromContentDisposition(response.headers.get('Content-Disposition')),
        data: response.body
      }
    }));
  }

  public isBusy(employee_ids: number[], dates: Date[], excluded_unit_ids?: number[]): Observable<number[]> {
    let params = {
      "employee_ids[]": employee_ids.map(id => "" + id),
      "dates[]": dates.map(date => formatDateForBackend(date))
    };
    if (excluded_unit_ids) {
      params["excluded_unit_ids[]"] = excluded_unit_ids;
    }
    return this.httpClient.get<number[]>(this.ROUTES.is_busy, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public generateMultipleCertificates(participant_ids: number[]): Observable<Blob> {
    let params = {
      "ids[]": participant_ids.map(id => "" + id)
    };
    return this.httpClient.get(this.ROUTES.generate_multiple_certificates, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }
  
  public generateBillingLine(
    participant_id: number,
  ): Observable<ParticipantDTO> {
    return this.httpClient.post<ParticipantDTO>(
      this.ROUTES.generateBillingLine,
      {
        participant_id: participant_id,
      }
    );
  }
  public generateMultipleBillingLine(
    ids: number[],
  ): Observable<ParticipantDTO[]> {
    return this.httpClient.post<ParticipantDTO[]>(
      this.ROUTES.generateMultipleBillingLine,
      {
        ids: ids,
      }
    );
  }
  
}
