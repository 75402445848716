import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

  public confirmMessage: string;
  public title: string;

  confirmCheck: boolean = true;

  private _checkMessage: string;

  get checkMessage(): string {
    return this._checkMessage;
  }

  set checkMessage(checkMessage: string) {
    if (checkMessage) {
      this.confirmCheck = false;
    }
    this._checkMessage = checkMessage;
  }
}
