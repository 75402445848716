import { Project, ProjectDTO } from 'src/app/_models/project';
import { createAction, props } from '@ngrx/store';
import { ActivityDTO, ActivityFilters } from 'src/app/_models/activity';
import { BillingLineDTO } from 'src/app/_models/billing-line.model';
import { User } from 'src/app/_models/user';


export const addActivity = createAction('[Activities] Add activity', props<{ startDate?: Date, endDate?: Date }>());
export const showActivity = createAction('[Activities] Show activity', props<{ currentActivity: ActivityDTO }>());

export const loadCurrentActivity = createAction('[Activities] Load current activity',);
export const loadCurrentActivityCompleted = createAction('[Activities] Load current activity completed', props<{ currentActivity: ActivityDTO }>());
export const loadCurrentActivityFailed = createAction('[Activities] Load current activity failed', props<{ error: any }>());

export const loadActivity = createAction('[Activities] Load  activity', props<{ id: number }>());
export const loadActivityCompleted = createAction('[Activities] Load  activity completed', props<{ activity: ActivityDTO }>());
export const loadActivityFailed = createAction('[Activities] Load  activity failed', props<{ error: any }>());

export const loadActivities = createAction('[Activities] Load activities', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: Partial<ActivityFilters>, includes?: string[] }>());
export const loadActivitiesCompleted = createAction('[Activities] Load activities Completed', props<{ activities: ActivityDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: Partial<ActivityFilters>, includes?: string[] }>());
export const loadActivitiesFailed = createAction('[Activities] Load activities Failed', props<{ error: any }>());

export const changePage = createAction('[Activities] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Activities] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Activities] Change filters', props<{ filters: Partial<ActivityFilters> }>());

export const editActivity = createAction('[Activities] Edit activity', props<{ activity: ActivityDTO, project?: Project }>());
export const activityDialogOpened = createAction('[Activities] Detail dialog opened', props<{ dialogId: string }>());
export const closeActivityDialog = createAction('[Activities] Close detail dialog', props<{ dialogId?: string }>());

export const saveActivity = createAction('[Activities] Save activity', props<{ activity: ActivityDTO }>());
export const saveActivityCompleted = createAction('[Activities] Save activity completed', props<{ activity: ActivityDTO }>());
export const saveActivityFailed = createAction('[Activities] Save activity failed', props<{ error: any }>());

export const deleteActivity = createAction('[Activities] Delete activity', props<{ activity: ActivityDTO }>());
export const deleteActivityCompleted = createAction('[Activities] Delete activity completed', props<{ activity: ActivityDTO }>());
export const deleteActivityFailed = createAction('[Activities] Delete activity failed', props<{ error: any }>());

export const duplicateActivity = createAction('[Activities] Duplicate activity', props<{ activity: ActivityDTO }>());
export const saveDuplicatedActivity = createAction('[Activities] Save duplicated activity', props<{ activity: ActivityDTO }>());
export const saveDuplicatedActivityCompleted = createAction('[Activities] Save duplicated  activity completed', props<{ activity: ActivityDTO }>());
export const saveDuplicatedActivityFailed = createAction('[Activities] Save duplicated activity failed', props<{ error: any }>());

export const selectActivity = createAction('[Activities] Select activity', props<{ filters?: Partial<ActivityFilters> }>());
export const selectionDialogOpened = createAction('[Activities] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Activities] Close selection dialog');
export const activitySelected = createAction('[Activities] Activity selected', props<{ activity: ActivityDTO }>());

export const generateBillingLine = createAction('[Activities] Generate BillingLine', props<{ activity: ActivityDTO}>());
export const generateBillingLineCompleted = createAction('[Activities] Generate BillingLine completed', props<{ billingLine: BillingLineDTO }>());
export const generateBillingLineFailed = createAction('[Activities] Generate BillingLine failed', props<{ error: any }>());

export const filterByUsers = createAction('[Activities] Filter By Users', props<{ userIds: number[]}>());
export const userFilterDialogOpen = createAction('[Activities] User Filter Dialog open');
export const userFilterDialogOpened = createAction('[Activities] User Filter Dialog opened', props<{ userDialogId: string }>());
export const userFilterDialogClose = createAction('[Activities] User Filter Dialog close');

export const activitiesExport = createAction('[Activities] Export');
export const activitiesExportCompleted = createAction('[Activities] Export Completed', props<{ data: Blob , type?: string }>());
export const activitiesExportFailed = createAction('[Activities] Export Failed', props<{ error: any }>());


