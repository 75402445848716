import { LOCALE_ID } from "@angular/core";
import { Inject } from "@angular/core";
import { Pipe, PipeTransform } from "@angular/core";
import { getLocaleCurrencySymbol } from "@angular/common";

@Pipe({
  name: "currencyLocal"
})
export class CurrencyLocalPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(value: number): any {
    return (
      new Intl.NumberFormat(this.locale, {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value) + ' ' + getLocaleCurrencySymbol(this.locale)
    );
  }
}
