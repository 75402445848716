import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../_helpers/listResult.interface';
import { PlannedUnit } from '../_models/planning';
import { LaravelPlannedUnitService } from './laravel';

@Injectable({ providedIn: "root" })
export class PlannedUnitService {
  constructor(private laravelPlannedUnitService: LaravelPlannedUnitService) { }

  public updatePlannedUnit(plannedUnit: PlannedUnit): Observable<PlannedUnit> {
    return this.laravelPlannedUnitService
      .updatePlannedUnit(
        plannedUnit.objectId,
        plannedUnit.toDTO(),
        plannedUnit.attachments.map(a => a.file)
      )
      .pipe(
        map(dto => {
          return new PlannedUnit(dto);
        })
      );
  }

  public deletePlannedUnit(plannedUnit: PlannedUnit): Observable<PlannedUnit> {
    return this.laravelPlannedUnitService
      .deletePlannedUnit(plannedUnit.objectId)
      .pipe(
        map(() => {
          return plannedUnit;
        })
      );
  }

  public getPlannedUnit(
    plannedUnitId: number,
    include?: string | string[]
  ): Observable<PlannedUnit> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelPlannedUnitService
      .getPlannedUnitById(plannedUnitId, includes)
      .pipe(map(dto => new PlannedUnit(dto)));
  }

  public getAllPlannedUnits(
    filter: PlannedUnitFilter,
    include?: string | string[]
  ): Observable<ListResult<PlannedUnit>> {
    return this.getPlannedUnits(1, 0, "start", "asc", filter, include);
  }

  public getPlannedUnits(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter: PlannedUnitFilter,
    include?: string | string[]
  ): Observable<ListResult<PlannedUnit>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelPlannedUnitService
      .getPlannedUnits(
        page,
        perPage,
        order,
        direction,
        filter ? filter.startDate : null,
        filter ? filter.endDate : null,
        filter ? filter.educationUnitId : null,
        includes
      )
      .pipe(
        map(response => {
          return {
            data: response.data.map(dto => new PlannedUnit(dto)),
            total: response.total
          };
        })
      );
  }

  public generateSummary(plannedUnitId: number): Observable<Blob> {
    return this.laravelPlannedUnitService
      .generateSummary(plannedUnitId)
      .pipe(map(data => new Blob([data], { type: "text/csv" })));
  }
}

export class PlannedUnitFilter {
  startDate?: Date;
  endDate?: Date;
  educationUnitId?: number;
}
