import { AppMaterialModule } from "./../app-material/app-material.module";
import { SharedModule } from "./../commons/shared.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginRoutingModule } from './login-routing.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    LoginRoutingModule
  ],
  declarations: [LoginComponent],
})
export class LoginModule {}
