import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {Observable} from 'rxjs';
import {PAGE_SIZE_OPTIONS} from 'src/app/_helpers/table.helper';
import {Project, ProjectFilters} from 'src/app/_models/project';


export type ProjectsColumn = "identifier" | "client" | "area" | "class" | "offer_number" | "order_number" | "accountant" | "course_id" | "description" | "start_date" | "actions" | "new_tab";

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  projects: Observable<Project[]>;

  @Input()
  defaultFilters: Partial<ProjectFilters>;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Project | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ProjectsColumn[] = ["identifier", "client", "area", "class", "offer_number", "order_number", "accountant", "description", "new_tab"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<Partial<ProjectFilters>> = new EventEmitter<Partial<ProjectFilters>>();

  @Output()
  onSelectProject: EventEmitter<Project> = new EventEmitter<Project>();
  @Output()
  onAddProject: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditProject: EventEmitter<Project> = new EventEmitter<Project>();
  @Output()
  onShowProject: EventEmitter<Project> = new EventEmitter<Project>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addProject() {
    this.onAddProject.emit();
  }

  editProject(project: Project) {
    this.onEditProject.emit(project);
  }

  selectProject(project: Project) {
    this.onSelectProject.emit(project);
  }
  showProject(project: Project) {
    this.onShowProject.emit(project);
  }

  onFilter(filters: Partial<ProjectFilters>) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
