import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Role } from 'src/app/_models/user';
import { getCurrentUser, State } from 'src/app/_store';






@Directive({ selector: "[showFor]" })
export class ShowForDirective implements OnInit {
    private destroy$ = new Subject();
    private _role: Role[];

    constructor(private templateRef: TemplateRef<any>, private store$: Store<State>, private viewContainer: ViewContainerRef) { }

    get roles(): Role[] {
        return this._role;
    }

    @Input()
    set showFor(role: Role[]) {
        this._role = role;
    }
    ngOnInit() {
        this.store$
            .pipe(
                select(getCurrentUser),
                distinctUntilChanged(),
                takeUntil(this.destroy$)
            )
            .subscribe(currentUser => {
                let show = false;
                if (currentUser && this.roles && this.roles.length > 0) {
                    let temp = this.roles.map(role => currentUser.hasRole(role))
                    show = temp.indexOf(true) != -1;
                }

                if (show) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });

    }



    // ngOnDestroy() {
    //     this.destroy$.next();
    //     this.destroy$.complete();
    // }
}
