import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BillingLine } from 'src/app/_models/billing-line.model';
import { AlertService } from 'src/app/_services/alert.service';
import { AppState } from 'src/app/_store/app.reducer';
import * as BillingLineSelectors from 'src/app/_store/billing-line.selectors';
import * as InvoiceActions from 'src/app/home/invoices/store/invoice.actions';
import { INVOICE_MODE } from 'src/app/_models/invoice';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.scss']
})
export class NewInvoiceComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  selectedBillinglines: BillingLine[];
  projects: string[];
  clients: string[];
  current: string;

  invoiceModes = INVOICE_MODE;

  invoiceModeCtrl: FormControl = new FormControl('', Validators.required);
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, private dialogRef: DialogRef<NewInvoiceComponent>) { }

  ngOnInit() {
    this.store$.pipe(
      select(BillingLineSelectors.getSelectedBillineLines),
      takeUntil(this.unsubscribe$),
    ).subscribe(
      (data) => {
        this.selectedBillinglines = data;
      }
    )
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _prepareSaveInvoice(): string {
    if(this.invoiceModeCtrl.value) {
      return this.invoiceModeCtrl.value;
    } else {
      return "Fattura unica"
    }
   
  }

  save() {
    let mode = this._prepareSaveInvoice();
    this.store$.dispatch(InvoiceActions.newInvoice({ mode }));
  }

  close() {
    this.alertService
      .showConfirmDialog(
        "Chiudi",
        "Sei sicuro di voler annullare la generazione delle fatture?"
      )
      .subscribe(result => {
        if (result) {
          this.store$.dispatch(InvoiceActions.closeInvoiceDialog({dialogId: this.dialogRef.id}))
        }
      });
  }
}
