import { createAction, props } from '@ngrx/store';
import { CalendarDTO } from 'src/app/_models/calendar';
import { OAuthDTO } from '../../../_models/google';

export const load = createAction('[Google] authenticate');
export const loadCompleted = createAction('[Google] authenticate Completed', props<{ authUrl?: string, email?: string }>());
export const loadFailed = createAction('[Google] authenticate Failed', props<{ error: any }>());

export const setCalendar = createAction('[Google] Set calendarId form user', props<{ calendarId: string }>());
export const setCalendarCompleted = createAction('[Google] Set calendarId Completed');
export const setCalendarFailed = createAction('[Google] Set calendarId Failed', props<{ error: any }>());

export const authenticateCode = createAction('[Google] Authenticate on calendar', props<{ code: string }>());
export const authenticateCodeCompleted = createAction('[Google] Authenticate on calendar Completed');
export const authenticateCodeFailed = createAction('[Google] Authenticate on calendar Failed', props<{ error: any }>());

export const loadCalendars = createAction('[Calendars] Load calendars');
export const loadCalendarsCompleted = createAction('[Calendars] Load calendars Completed', props<{ calendars: CalendarDTO[] }>());
export const loadCalendarsFailed = createAction('[Calendars] Load calendars Failed', props<{ error: any }>());

export const loadToken = createAction('[Google] Load token');
export const loadTokenCompleted = createAction('[Google] Load token Completed', props<OAuthDTO>());
export const loadTokenFailed = createAction('[Google] Load token Failed', props<{ error: any }>());

export const openOauth = createAction('[Google] Open oauth', props<{ url: string }>());
export const oauthClosed = createAction('[Google] Oauth closed');

export const disconnect = createAction('[Google] Disconnect');
export const disconnectCompleted = createAction('[Google] Disconnect Completed');
export const disconnectFailed = createAction('[Google] Disconnect Failed', props<{ error: any }>());
export const disconnectCanceled = createAction('[Google] Disconnect Cancelled');