import { createSelector } from '@ngrx/store';

import { selectBillingLineState } from 'src/app/_store/index';
import { BillingLineState } from 'src/app/_store/billing-line.reducer';

// BillingLine Selectors

export const getBillingLinesTableState = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getBillingLines = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => state.list
);

export const getTotalBillingLines = createSelector(
  getBillingLinesTableState,
  (state: BillingLineState) => state.total
);

export const getBillingLinesCurrentPage = createSelector(
  getBillingLinesTableState,
  (state: BillingLineState) => state.currentPage
);

export const getBillingLinesPerPage = createSelector(
  getBillingLinesTableState,
  (state: BillingLineState) => state.perPage
);

export const getBillingLinesOrder = createSelector(
  getBillingLinesTableState,
  (state: BillingLineState) => state.order
);

export const getBillingLinesDirection = createSelector(
  getBillingLinesTableState,
  (state: BillingLineState) => state.direction
);

export const getFilters = createSelector(
  getBillingLinesTableState,
  (state: BillingLineState) => state.filters
);

export const getBillingLineDialogId = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => state.dialogId
);

export const getSelectedBillingLine = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => state.billingLine
);
export const getSelectionDialogId = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => state.selectionDialogId
);

export const getSelectedBillineLines = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => state.selectedBillingLines
);
