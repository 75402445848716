import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { UsersDataSource } from 'src/app/home/settings/users/user-list/users.datasource';
import { User, UserFilters } from 'src/app/_models/user';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { State } from 'src/app/_store/';

import { UserService } from '../../_services/user.service'
import * as UserActions from 'src/app/_store/user.actions';
import { PAGE_SIZE_OPTIONS } from './../../_helpers/table.helper';

@Component({
  selector: "app-user-selection-dialog",
  templateUrl: "./user-selection-dialog.component.html",
  styleUrls: ["./user-selection-dialog.component.scss"]
})
export class UserSelectionDialogComponent implements OnInit {
  displayedColumns = ["email", "name", "surname", "action"];
  dataSource: UsersDataSource;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  queryString: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  multiple: boolean;
  @Input()
  userFilters: UserFilters

  onlyPartners: boolean = false;

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<UserSelectionDialogComponent>,
    private store$: Store<State>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      this.multiple = this.data.multiple;
      this.onlyPartners = this.data.onlyPartners;
      this.userFilters = this.data.filters;
    }
    this.dataSource = new UsersDataSource(this.userService);
    this.loadUsers();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadUsers()))
      .subscribe();
  }

  loadUsers() {
    return this.dataSource.loadUsers(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.queryString,
      this.userFilters
    );
  }

  reload() {
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(this.paginator.pageSize);
    this.loadUsers();
  }

  selectUser(user: User) {
    this.dialogRef.close(user);
    if (user) {
      this.store$.dispatch(UserActions.userSelected({ user: user.toDTO() }));
    }
  }

  close() {
    this.dialogRef.close();
  }
}
