import { createSelector } from '@ngrx/store';
import { selectArticleState } from 'src/app/_store';

import { ArticleState } from '../reducers/article.reducer';

// Article Selectors

export const getArticlesTableState = createSelector(
  selectArticleState,
  (state: ArticleState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getArticles = createSelector(
  selectArticleState,
  (state: ArticleState) => state.list
);

export const getTotalArticles = createSelector(
  getArticlesTableState,
  (tableState) => tableState.total
);

export const getArticlesCurrentPage = createSelector(
  getArticlesTableState,
  (tableState) => tableState.currentPage
);

export const getArticlesPerPage = createSelector(
  getArticlesTableState,
  (tableState) => tableState.perPage
);

export const getArticlesOrder = createSelector(
  getArticlesTableState,
  (tableState) => tableState.order
);

export const getArticlesDirection = createSelector(
  getArticlesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getArticlesTableState,
  (tableState) => tableState.filters
);

export const getArticleDialogId = createSelector(
  selectArticleState,
  (state: ArticleState) => state.dialogId
);

export const getSelectedArticle = createSelector(
  selectArticleState,
  (state: ArticleState) => state.article
);
export const getSelectionDialogId = createSelector(
  selectArticleState,
  (state: ArticleState) => state.selectionDialogId
);
