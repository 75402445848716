
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { UserDTO } from 'src/app/_models/user';
import * as UserSelectors from 'src/app/_store/user.selectors';

import { AlertService } from 'src/app/_services/alert.service';

import * as UserActions from 'src/app/_store/user.actions';
import * as RouterActions from 'src/app/_store/router.actions';
import { State, getCurrentUser } from 'src/app/_store';
import { getSelectedUser, getSelectionDialogId } from 'src/app/_store/user.selectors';
import { UserSelectionDialogComponent } from 'src/app/commons/user-selection-dialog/user-selection-dialog.component';
import { PasswordChangeComponent } from '../home/settings/users/password-change/password-change.component';
import { LaravelUserService } from '../_services/laravel';



@Injectable()
export class UserEffects {
    selectUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.selectUser),
        map(({filters}) => {
            let dialogRef = this.dialog.open(UserSelectionDialogComponent ,  {
              data: {
                filters,
              }
            });
            return UserActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
        }))
    );

    closeSelectionDialog = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.closeSelectionDialog),
            withLatestFrom(this.store$.select(getSelectionDialogId)),
            tap(([_, dialogId]) => {
                if (dialogId) {
                    this.dialog.getDialogById(dialogId).close();
                }
            })
        ), { dispatch: false }
    );

    usersSelected$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.userSelected),
            map(() => UserActions.closeSelectionDialog())
        ),
    );

    changeUserPassword$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.changeUserPassword),
        map(({ user }) => {
          let dialogRef = this.dialog.open(PasswordChangeComponent, {
            data: {
              user
            }
          });
          return UserActions.changePasswordDialogOpen({ dialogId: dialogRef.id });
        }))
      );
    
      updatePassword$ = createEffect(() =>
        this.actions$.pipe(
          ofType(UserActions.updatePassword),
          flatMap(({ newPassword, user }) => {
            if (user) {
              return of({ newPassword, user });
            } else {
              return this.store$.select(getCurrentUser).pipe(take(1), map(user => {
                return { newPassword, user }
              }));
            }
          }),
          switchMap(({ newPassword, user }) =>
            this.userService.changePassword(user.objectId, newPassword).pipe(
              map(user =>
                UserActions.updatePasswordCompleted({ user })
              ),
              catchError(error => {
                return of(UserActions.updatePasswordFailed({ error }))
              })
            )
          )
        )
      );
    
      onUpdatePasswordCompleted$ = createEffect(() =>
        this.actions$.pipe(
          ofType(UserActions.updatePasswordCompleted),
          map(action => action.user),
          tap(user => this.alertService.showConfirmMessage(`Password per ${user.email} aggiornata con successo`)),
          map(() => UserActions.closeChangePasswordDialog())));
    
      closeChangePasswordDialog = createEffect(() =>
      this.actions$.pipe(
        ofType(UserActions.closeChangePasswordDialog),
        withLatestFrom(this.store$.select(UserSelectors.getChangePasswordDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      ), { dispatch: false }
    );  

    constructor(
        private actions$: Actions,
        private store$: Store<State>,
        private dialog: MatDialog,
        private alertService: AlertService,
        private userService: LaravelUserService,
    ) { }
}
