import { Action, createReducer, on } from "@ngrx/store";
import { CostExtraDTO, CostExtraFilters } from "../_models/costExtra";
import * as CostExtraActions from './cost-extra.actions';


export interface CostExtraState {
  list: CostExtraDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: Partial<CostExtraFilters>,
  dialogId: string,
  selectionDialogId: string,
  currentCostExtra: CostExtraDTO,
  costExtraId: number
};

const initialState: CostExtraState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  costExtraId: null,
  currentCostExtra: null
};


const costExtraReducer = createReducer(
  initialState,
  on(CostExtraActions.loadCostExtrasCompleted, (state, { costExtras, currentPage, total, perPage, order, direction, includes }) => {
    return { ...state, list: costExtras, currentPage, total, perPage, order, direction, includes };
  }),
  on(CostExtraActions.CostExtraDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(CostExtraActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(CostExtraActions.loadCostExtras, (state, { filters }) => {
    return { ...state, filters };
  }),
  on(CostExtraActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(CostExtraActions.showCostExtra, (state, { currentCostExtra }) => {
    return { ...state, currentCostExtra };
  }),
  on(CostExtraActions.addCostExtra, (state) => {
    return { ...state, currentCostExtra: null };
  }),
  on(CostExtraActions.editCostExtra, (state) => {
    return { ...state, currentCostExtra: null };
  }),
  on(CostExtraActions.loadCurrentCostExtraCompleted, (state, { currentCostExtra }) => {
    return { ...state, currentCostExtra };
  }),
  on(CostExtraActions.loadCostExtraCompleted, (state, { costExtra }) => {
    return { ...state, currentCostExtra: costExtra };
  }),

);

export function reducer(state: CostExtraState | undefined, action: Action) {
  return costExtraReducer(state, action);
}
