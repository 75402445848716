import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { ListResultDTO } from "../../_helpers/listResult.interface";
import { environment } from "./../../../environments/environment";
import { UserDTO, UserFilters } from "./../../_models/user";

export interface LoginSuccessDTO {
  success: {
    token: string;
    user: UserDTO;
  };
}

@Injectable({ providedIn: "root" })
export class LaravelUserService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/user",
      list: environment["laravel"]["serverUrl"] + "/api/users",
      store: environment["laravel"]["serverUrl"] + "/api/user",
      login: environment["laravel"]["serverUrl"] + "/api/login",
      logout: environment["laravel"]["serverUrl"] + "/api/logout",
      currentUser: environment["laravel"]["serverUrl"] + "/api/currentUser",
      delete: environment["laravel"]["serverUrl"] + "/api/user",
      changePassword: environment["laravel"]["serverUrl"] + "/api/changePassword"
    };
  }

  login(email: string, password: string): Observable<LoginSuccessDTO> {
    return this.httpClient.post<LoginSuccessDTO>(this.ROUTES.login, {
      email: email,
      password: password
    });
  }

  logout(): Observable<any> {
    return this.httpClient.post(this.ROUTES.logout, {});
  }

  getCurrentUser(): Observable<UserDTO> {
    return this.httpClient.get<UserDTO>(this.ROUTES.currentUser, {});
  }

  userHasRole(id: number, roleName: string): Observable<boolean> {
    //TODO mrosetti - To be implemented
    return of(true);
  }

  getUserById(id: number, include?: string[]): Observable<UserDTO> {
    let params = {
      id: "" + id
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<UserDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public getUsers(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: string,
    filters?: UserFilters,
    include?: string[]
  ): Observable<ListResultDTO<UserDTO>> {
    if (per_page) {
      return this.getPaginatedResults(
        page,
        per_page,
        order,
        direction,
        filter,
        filters,
        include
      );
    } else {
      return this.getAllResults(order, direction, filter, include).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  private getPaginatedResults(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: string,
    filters?: UserFilters,
    include?: string[]
  ): Observable<ListResultDTO<UserDTO>> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (filter) params["filter"] = "" + filter;
    if (include) params["include[]"] = include;
    if (filters) { if (filters.active != undefined && filters.active != null) params["active"] = filters.active;}

    return this.httpClient.get<ListResultDTO<UserDTO>>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  private getAllResults(
    order: string,
    direction: string,
    filter?: string,
    include?: string[]
  ): Observable<UserDTO[]> {
    let params = {};

    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (filter) params["filter"] = "" + filter;
    if (include) params["include[]"] = include;

    return this.httpClient.get<UserDTO[]>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public createUser(dto: UserDTO): Observable<UserDTO> {
    return this.httpClient.post<UserDTO>(this.ROUTES.store, {
      user: dto
    });
  }

  public updateUser(id: number, dto: UserDTO): Observable<UserDTO> {
    dto.id = id;
    return this.httpClient.put<UserDTO>(this.ROUTES.store, { user: dto });
  }

  public archiveUser(id: number): Observable<UserDTO> {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete<UserDTO>(this.ROUTES.delete, {
      params: params
    });
  }

  public changePassword(id: number, new_password: string): Observable<UserDTO> {
    return this.httpClient.put<UserDTO>(this.ROUTES.changePassword, { id, new_password });
  }

  // public changePassword(id: number, newPassword: string): Observable<UserDTO> {
  //   let params = {
  //     id: "" + id,
  //     new_password: newPassword
  //   };
  //   return this.httpClient.put<UserDTO>(this.ROUTES.changePassword, {
  //     params: new HttpParams({ fromObject: params })
  //   });
  // }
}
