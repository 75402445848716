<header mat-dialog-title class="u-flex-full">
  <span>
    Seleziona attività
  </span>
</header>
<mat-dialog-content>
  <app-activity-list [activities]="activities" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns"
    [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectActivity)="selectActivity($event)"></app-activity-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>