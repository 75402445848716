import { createSelector } from '@ngrx/store';
import { CalendarEvent } from 'angular-calendar';

import { User } from 'src/app/_models/user';
import { selectInvoiceState } from 'src/app/_store';

import { InvoiceState } from './invoice.reducer';


// Invoice Selectors

export const getInvoicesTableState = createSelector(
  selectInvoiceState,
  (state: InvoiceState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getInvoices = createSelector(
  selectInvoiceState,
  (state: InvoiceState) => state.list
);


export const getTotalInvoices = createSelector(
  getInvoicesTableState,
  (tableState) => tableState.total
);

export const getInvoicesCurrentPage = createSelector(
  getInvoicesTableState,
  (tableState) => tableState.currentPage
);

export const getInvoicesPerPage = createSelector(
  getInvoicesTableState,
  (tableState) => tableState.perPage
);

export const getInvoicesOrder = createSelector(
  getInvoicesTableState,
  (tableState) => tableState.order
);

export const getInvoicesDirection = createSelector(
  getInvoicesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getInvoicesTableState,
  (tableState) => tableState.filters
);

export const getInvoiceDialogId = createSelector(
  selectInvoiceState,
  (state: InvoiceState) => state.dialogId
);

export const getSelectionDialogId = createSelector(
  selectInvoiceState,
  (state: InvoiceState) => state.selectionDialogId
);

export const getCurrentInvoice = createSelector(
  selectInvoiceState,
  (state: InvoiceState) => state.currentInvoice
);
export const getInvoiceId = createSelector(
  selectInvoiceState,
  (state: InvoiceState) => state.invoiceId
);
export const getCheckResponse = createSelector(
  selectInvoiceState,
  (state: InvoiceState) => state.checkResponse
);
export const getSelectedInvoices = createSelector(
  selectInvoiceState,
  (state: InvoiceState) => state.selectedInvoices
);

