import { AbstractControl } from '@angular/forms';

import { environment } from './../../environments/environment';
import { Base, BaseDTO } from './base';

export interface AttachmentDTO extends BaseDTO {
  type?: string;
  description?: string;
  filename?: string;
  url?: string;
}

export class Attachment extends Base {
  type?: string;
  description?: string;
  file?: File;
  filename?: string;
  private _url?: string;

  constructor(dto: AttachmentDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.type = dto.type;
      this.description = dto.description;
      this.filename = dto.filename;
      this._url = dto.url;
    }
  }

  public toDTO(): AttachmentDTO {
    let result: AttachmentDTO = <AttachmentDTO>super.toDTO();
    Object.assign(result, {
      type: this.type,
      description: this.description,
      filename: this.filename
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: Attachment
  ): Attachment {
    const formModel = formGroup.value;

    let attachment: Attachment = new Attachment(null);

    attachment.objectId = formModel.objectId;
    attachment.type = formModel.type;
    attachment.description = formModel.description;
    attachment.file = formModel.file;

    if (original) {
      attachment.objectId = original.objectId;
      attachment.loadedRelations = original.loadedRelations;
    }
    return attachment;
  }

  get url(): string {
    return this._url
      ? environment["laravel"]["serverUrl"] + "/" + this._url
      : null;
  }

  set url(url: string) {
    this._url = url;
  }
}
