<header mat-dialog-title>
  Importa da file
</header>
<mat-dialog-content>
  <div>
    <button *ngIf="!file" mat-raised-button color="basic" (click)="fileInput.click()">Scegli file da importare</button>
    <input accept=".csv,.CSV" class="hidden" type="file" #fileInput (change)="fileChanged($event)" />
    <span *ngIf="file">File selezionato: {{file.name}}</span>
  </div>
  <div style="margin-top: 15px;">
    <a href="assets/templates/import_partecipanti.csv" target="_blank">Formato di importazione</a>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" [disabled]="!file" (click)="startImport() ">Importa</button>
  <button mat-raised-button color="basic" (click)="close() ">Chiudi</button>
</mat-dialog-actions>
