import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResultDTO } from '../../_helpers/listResult.interface';
import { environment } from './../../../environments/environment';
import { EmployeeDTO, EmployeeFilter } from './../../_models/employee';

@Injectable({ providedIn: "root" })
export class LaravelEmployeeService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/employee",
      list: environment["laravel"]["serverUrl"] + "/api/employees",
      store: environment["laravel"]["serverUrl"] + "/api/employee",
      restore: environment["laravel"]["serverUrl"] + "/api/employee/restore",
      delete: environment["laravel"]["serverUrl"] + "/api/employee",
      change_org:
        environment["laravel"]["serverUrl"] + "/api/employee/change_org",
      checkIdentifierExists:
        environment["laravel"]["serverUrl"] + "/api/checkIdentifierExists",
      export_status:
        environment["laravel"]["serverUrl"] + "/api/employee/export_status",
    };
  }

  getEmployeeById(id: number, include?: string[]): Observable<EmployeeDTO> {
    let params = {
      id: "" + id,
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<EmployeeDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params,
      }),
    });
  }

  public getEmployees(
    org_id: number,
    only_private: boolean,
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: EmployeeFilter,
    include?: string[]
  ): Observable<ListResultDTO<EmployeeDTO>> {
    let params = {};
    if (org_id) params["org_id"] = "" + org_id;
    if (only_private) params["only_private"] = "" + only_private;

    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) {
      if (filter.queryString) params["filter"] = filter.queryString;
      if (filter.includeArchived) params["include_archived"] = "true";
    }

    if (per_page) {
      if (page) params["page"] = "" + page;
      if (per_page) params["per_page"] = "" + per_page;
      return this.httpClient.get<ListResultDTO<EmployeeDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params }),
      });
    } else {
      return this.httpClient
        .get<EmployeeDTO[]>(this.ROUTES.list, {
          params: new HttpParams({ fromObject: params }),
        })
        .pipe(
          map((results) => {
            return {
              data: results,
              total: results.length,
            };
          })
        );
    }
  }

  public createEmployee(
    dto: EmployeeDTO,
    attachments?: any[]
  ): Observable<EmployeeDTO> {
    let formData: FormData = new FormData();
    formData.append("employee", JSON.stringify(dto));
    if (attachments) {
      attachments.forEach((attachment, index) => {
        formData.append(`attachment_${index}`, attachment);
      });
    }
    return this.httpClient.post<EmployeeDTO>(this.ROUTES.store, formData);
  }

  public updateEmployee(
    id: number,
    dto: EmployeeDTO,
    attachments?: any[]
  ): Observable<EmployeeDTO> {
    dto.id = id;
    let formData: FormData = new FormData();
    formData.append("employee", JSON.stringify(dto));
    if (attachments) {
      attachments.forEach((attachment, index) => {
        formData.append(`attachment_${index}`, attachment);
      });
    }
    return this.httpClient.post<EmployeeDTO>(this.ROUTES.store, formData); //mrosetti - Using PUT instead of POST will break the entire request, don't do it
  }

  public deleteEmployee(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public changeOrg(id: number, org_id: number): Observable<EmployeeDTO> {
    return this.httpClient.put<EmployeeDTO>(this.ROUTES.change_org, {
      id: id,
      org_id: org_id,
    });
  }

  public checkIdentifierExists(
    employee_id: number,
    identifier: string
  ): Observable<boolean> {
    let params = {};
    if (identifier) params["identifier"] = identifier;
    if (employee_id) params["employee_id"] = "" + employee_id;
    return this.httpClient.get<boolean>(this.ROUTES.checkIdentifierExists, {
      params: new HttpParams({ fromObject: params }),
    });
  }

  public restoreOrg(id): Observable<EmployeeDTO> {
    return this.httpClient.put<EmployeeDTO>(this.ROUTES.restore, { id: id });
  }

  public exportStatus(id: number): Observable<Blob> {
    let params = {
      id: "" + id,
    };
    return this.httpClient.get(this.ROUTES.export_status, {
      params: new HttpParams({
        fromObject: params,
      }),
      responseType: "blob",
    });
  }
}
