import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, HostBinding, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { User } from 'src/app/_models/user';
import { Subject } from 'rxjs';

import { UserSelectionDialogComponent } from '../user-selection-dialog/user-selection-dialog.component';

export const USER_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => UserFieldComponent),
};

@Component({
  selector: "app-user-field",
  templateUrl: "./user-field.component.html",
  styleUrls: ["./user-field.component.scss"],
  providers: [USER_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class UserFieldComponent
  implements ControlValueAccessor, MatFormFieldControl<User> {
  private unsubscribe$ = new Subject<void>();
  private _value: User;
  private _disabled = false;

  private _required = false;
  errorState: boolean; //TODO
  focused: boolean; //TODO
  controlType?: string = "user-selection-field";
  autofilled?: boolean;
  userAriaDescribedBy?: string;


  constructor(
    public dialog: MatDialog,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    //TODO
  }
  onContainerClick(event: MouseEvent): void {
    if (!this.disabled) {
      if ((event.target as Element).tagName.toLowerCase() != "mat-icon") {
        this.selectUser();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectUser() {
    this.dialog.open(UserSelectionDialogComponent).afterClosed().subscribe((selectedUser: User) => {
      this.value = selectedUser;
    });
  }

  clearUser() {
    this.value = null;
  }

  set value(value: User) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): User {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: User): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
