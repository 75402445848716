import { Pipe } from "@angular/core";
import { Role } from "src/app/_models/user";

@Pipe({ name: "userRole", pure: true })
export class UserRolePipe {
  transform(value: Role): string {
    switch (value) {
      case "admin":
        return "Admin";
      case "operator":
        return "Operatore";
      case "organizer":
        return "Organizzatore";
      default:
        return "Ruolo non valido";
    }
  }
}
