<div role="group" class="date-month-input-container u-flex-full"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField?.getLabelId()"
     >
     <mat-select placeholder="Giorno" formControlName="day" #day>
      <mat-option *ngFor="let day of DAYS" [value]="day">{{day}}</mat-option>
     </mat-select>
     <mat-select placeholder="Mese" formControlName="month" #month>
        <mat-option value="0">Gennaio</mat-option>
        <mat-option value="1">Febbraio</mat-option>
        <mat-option value="2">Marzo</mat-option>
        <mat-option value="3">Aprile</mat-option>
        <mat-option value="4">Maggio</mat-option>
        <mat-option value="5">Giugno</mat-option>
        <mat-option value="6">Luglio</mat-option>
        <mat-option value="7">Agosto</mat-option>
        <mat-option value="8">Settembre</mat-option>
        <mat-option value="9">Ottobre</mat-option>
        <mat-option value="10">Novembre</mat-option>
        <mat-option value="11">Dicembre</mat-option>
     </mat-select>

</div>
