import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Invoice } from 'src/app/_models/invoice';
import { Participant } from 'src/app/_models/planning';
import { User } from 'src/app/_models/user';
import { AlertService } from 'src/app/_services/alert.service';
import { MailService } from 'src/app/_services/mail.service';
import { UserService } from 'src/app/_services/user.service';
import { getCurrentUser, State } from 'src/app/_store';




@Component({
  selector: "app-send-invoice-dialog",
  templateUrl: "./send-invoice-dialog.component.html",
  styleUrls: ["./send-invoice-dialog.component.scss"]
})
export class SendInvoiceDialogComponent implements OnInit {
  invoice: Invoice;

  mailForm: UntypedFormGroup;

  currentUser: User;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<State>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SendInvoiceDialogComponent>,
    public userService: UserService,
    public alertService: AlertService,
    public mailService: MailService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store.select(getCurrentUser).subscribe(currentUser => {
      this.currentUser = currentUser;
    })
    this.createForm();
  }



  ngOnInit() {
    this.invoice = this.data.invoice;
    console.log(this.data);
    
    this.initForm();
  }

  createForm() {
    let group = {
      sender: ["", [Validators.required, Validators.email]],
      subject: ["", Validators.required],
      template: ["", Validators.required],
      senderInBcc: "",
  
    };

    this.mailForm = this.fb.group(group);
  }

  initForm() {
    this.mailForm.patchValue({
      sender: this.currentUser.email,
      senderInBcc: true,
      subject: this.data.subject,
      template: this.data.template
    });
  }

  close() {
    this.dialogRef.close();
  }

  send() {
    const formModel = this.mailForm.value;
    this.mailService.sendPDF(this.invoice?.objectId, formModel.template,
      formModel.subject,
      formModel.sender,
      formModel.senderInBcc,
    ).subscribe(() => {
      this.alertService.showConfirmMessage(`E-mail inviata correttamente`);
      this.close();
    }, error => {
      this.alertService.showErrorMessage(`Errore nell'invio e-mail`, error);
    })
  
}
}
