import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { PAGE_SIZE_OPTIONS } from 'src/app/_helpers/table.helper';
import { Article, ArticleFilters } from 'src/app/_models/article';
import { AppState } from 'src/app/_store/app.reducer';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import * as ArticleActions from '../../home//store/actions/article.actions';
import * as ArticleSelectors from '../../home/store/selectors/article.selectors';
import { ArticlesColumn } from '../article-list/article-list.component';

@Component({
  selector: 'app-article-selection',
  templateUrl: './article-selection.component.html',
  styleUrls: ['./article-selection.component.scss']
})
export class ArticleSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  articles: Observable<Article[]>;
  filters: Observable<Partial<ArticleFilters>>;

  @Input()
  defaultFilters: Partial<ArticleFilters> = {};
  @Input()
  showExpenseFilter: boolean;

  displayedColumns: ArticlesColumn[] = ["code", "name", "area", "expense", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.articles = this.store$.pipe(select(ArticleSelectors.getArticles), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new Article(dto)) : null));
    this.total = this.store$.pipe(select(ArticleSelectors.getTotalArticles), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(ArticleSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
      this.showExpenseFilter = data.showExpenseFilter;
    }
  }

  load() {
    this.store$.dispatch(ArticleActions.loadArticles(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(ArticleActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(ArticleActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: Partial<ArticleFilters>) {
    this.store$.dispatch(ArticleActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectArticle(article: Article) {
    if (article) {
      this.store$.dispatch(ArticleActions.articleSelected({ article: article.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(ArticleActions.closeSelectionDialog())
  }
}
