import { createAction, props } from '@ngrx/store';
import { ArticleDTO, ArticleFilters } from 'src/app/_models/article';

export const loadArticles = createAction('[Articles] Load articles', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: Partial<ArticleFilters>, includes?: string[] }>());
export const loadArticlesCompleted = createAction('[Articles] Load articles Completed', props<{ articles: ArticleDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: Partial<ArticleFilters>, includes?: string[] }>());
export const loadArticlesFailed = createAction('[Articles] Load articles Failed', props<{ error: any }>());

export const changePage = createAction('[Articles] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Articles] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Articles] Change filters', props<{ filters: Partial<ArticleFilters> }>());

export const editArticle = createAction('[Articles] Edit article', props<{ article: ArticleDTO }>());
export const articleDialogOpened = createAction('[Articles] Detail dialog opened', props<{ dialogId: string }>());
export const closeArticleDialog = createAction('[Articles] Close detail dialog');

export const saveArticle = createAction('[Articles] Save article', props<{ article: ArticleDTO }>());
export const saveArticleCompleted = createAction('[Articles] Save article completed', props<{ article: ArticleDTO }>());
export const saveArticleFailed = createAction('[Articles] Save article failed', props<{ error: any }>());

export const deleteArticle = createAction('[Articles] Delete article', props<{ article: ArticleDTO }>());
export const deleteArticleCompleted = createAction('[Articles] Delete article completed', props<{ article: ArticleDTO }>());
export const deleteArticleFailed = createAction('[Articles] Delete article failed', props<{ error: any }>());

export const selectArticle = createAction('[Articles] Select article', props<{ filters?: Partial<ArticleFilters>, showExpenseFilter?: boolean }>());
export const selectionDialogOpened = createAction('[Articles] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Articles] Close selection dialog');
export const articleSelected = createAction('[Articles] Article selected', props<{ article: ArticleDTO }>());
