<app-article-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters" [showExpenseFilter]="showExpenseFilter">
</app-article-filters>
<mat-card class="u-center-text" *ngIf="!(articles | async).length">
  <h4>Nessun articolo trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addArticle()">Aggiungi</button>
</mat-card>
<div class="table-container" [hidden]="!(articles | async).length">
  <table mat-table matSort [dataSource]="articles">
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codice Mexal </th>
      <td mat-cell *matCellDef="let row">
        {{ row.code }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let row">
        {{row.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="area">
      <th mat-header-cell *matHeaderCellDef> Area</th>
      <td mat-cell *matCellDef="let row">
        {{row.area}}
      </td>
    </ng-container>

    <ng-container matColumnDef="expense">
      <th mat-header-cell *matHeaderCellDef>Voce di spesa</th>
      <td mat-cell *matCellDef="let row">
        {{row.expense ? 'Sì' : 'No'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation();addArticle()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editArticle(row)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectArticle(row)">Seleziona</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
