import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Invoice } from 'src/app/_models/invoice';
import { AlertService } from 'src/app/_services/alert.service';
import { AppState } from 'src/app/_store/app.reducer';
import * as InvoiceActions from 'src/app/home/invoices/store/invoice.actions';


@Component({
  selector: 'app-invoice-cancel',
  templateUrl: './invoice-cancel.component.html',
  styleUrls: ['./invoice-cancel.component.scss']
})
export class InvoiceCancelComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _invoice: Invoice;

  invoiceForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data) {
      this.invoice = this.data.invoice;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      canceledDate: ["", Validators.required],
    }
    this.invoiceForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.invoiceForm) {
      this.invoiceForm.reset();
      if (this.invoice) {
        this._initFormValues(this.invoice);
      }
    }

  }

  private _initFormValues(invoice: Invoice) {
    if (invoice) {
      this.invoiceForm.patchValue({
        canceledDate: invoice.canceledDate,
      });
    }
  }

  get invoice(): Invoice {
    return this._invoice;
  }

  set invoice(invoice: Invoice) {
    this._invoice = invoice;
    this.ngOnChanges();
  }

  private _prepareSaveInvoice(): Invoice {
    let savingInvoice: Invoice = Invoice.fromFormGroup(this.invoiceForm, this.invoice);
    return savingInvoice;
  }

  save() {
    let unsavedEntity = this._prepareSaveInvoice();
    this.store$.dispatch(InvoiceActions.cancelInvoice({ invoice: unsavedEntity.toDTO() }));
  }

  close() {
    if (this.invoiceForm.pristine) {
      this.store$.dispatch(InvoiceActions.closeInvoiceDialog({}))
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(InvoiceActions.closeInvoiceDialog({}))
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
