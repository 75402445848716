import { Time } from '@angular/common';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

import { Base } from './../_models/base';

const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const BACKEND_DATE_FORMAT_WITH_TIME = "YYYY-MM-DD HH:mm:ss";

export function updateArray(
  oldArray: Array<Base>,
  newArray: Array<Base>,
  addIfNotExists?: boolean
) {
  if (!oldArray) {
    oldArray = newArray;
  } else {
    if (newArray) {
      newArray.forEach(newObject => {
        let itemIndex = oldArray.findIndex(
          item => item.objectId == newObject.objectId
        );
        if (itemIndex > -1) {
          oldArray[itemIndex] = newObject;
        } else if (addIfNotExists) {
          oldArray.push(newObject);
        }
      });
    }
  }
  return oldArray;
}

export function getFileType(fileName) {
  if (!fileName) return "";
  let extension = fileName
    .split(".")
    .pop()
    .toLowerCase();
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
    case "gif":
    case "tiff":
      return "image";
    case "mp4":
      return "video";
    case "wav":
    case "mp3":
      return "audio";
    default:
      return "file";
  }
}

export function formatDateForBackend(date: Date, includeTime: boolean = false) {
  if (date) {
    return moment(date).format(
      includeTime ? BACKEND_DATE_FORMAT_WITH_TIME : BACKEND_DATE_FORMAT
    );
  }
  return null;
}

export function autocompleteSelectionValidator(
  control: UntypedFormControl
): ValidationErrors | null {
  let selection = control.value;
  if (typeof selection === "string") {
    return { invalid: true };
  }
  return null;
}

export function getMinutesBetween(
  startTime: string,
  endTime: string
): number {
  if (startTime && endTime) {
    let startSplits = startTime.split(":");
    let endSplits = endTime.split(":");
    let start = moment();
    let end = moment();
    start.set({
      hours: Number(startSplits[0]),
      minutes: Number(startSplits[1])
    });

    end.set({
      hours: Number(endSplits[0]),
      minutes: Number(endSplits[1])
    });
    return end.diff(start, "minutes");
  }
  return 0;
}

export function getMinutesBetweenTimes(
  startTime: Time,
  endTime: Time
): number {
  if (startTime && endTime) {
    let start = moment();
    let end = moment();
    start.set({
      hours: Number(startTime.hours),
      minutes: Number(startTime.minutes)
    });

    end.set({
      hours: Number(endTime.hours),
      minutes: Number(endTime.minutes)
    });
    return end.diff(start, "minutes");
  }
  return 0;
}
export function minutesToTime(minutes : number): Time {

  const h = Math.floor(minutes/60);
  const m = minutes % 60;

   return {hours : h, minutes: m}
}

export function timeToMinutes( time : Time ) : number {
  const result = time.hours * 60 + time.minutes;
  return result;
}

export function addHours(startTime: string, hours: number): string {
  if (startTime && hours > 0) {
    let startSplits = startTime.split(":");
    let start = moment();
    start.set({
      hours: Number(startSplits[0]),
      minutes: Number(startSplits[1])
    });
    return start
      .add(hours, "hours")
      .format("HH:mm")
      .toString();
  }
  return "";
}

export function roundBy(number: number, rounder: number) {
  return Math.round(number / rounder) * rounder;
}

export function dateFromParts(year: number, month: number, day: number = 1): Date {
  return moment({ year, month, day }).toDate();
}

export function extractDay(date: Date): number {
  return moment(date).date();
}

export function extractMonth(date: Date): number {
  return moment(date).get('month');
}

export function extractYear(date: Date): number {
  return moment(date).get('year');
}

export function atLeastOneValidator(...fields: string[]) {
  return (controlGroup) => {
    if (fields) {
      const theOne = fields.find(name => !!controlGroup.controls[name].value);
      if (!theOne) {
        return {
          atLeastOneRequired: {
            text: 'At least one should be selected'
          }
        }
      }
    }
    return null;
  };
};
export function indexOfObject<T>(source: Array<T>, element: T): number {
  return source.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(element));
}

export function removeObjectFromArray<T>(source: Array<T>, element: T): Array<T> {
  const index = indexOfObject<T>(source, element);
  if (index >= 0) {
    source = [...source];
    source.splice(index, 1);
  }
  return source;
}

export const range = (from: number, to: number, step = 1) => [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step);
