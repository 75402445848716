import { map } from "rxjs/operators";
import { OrderDTO } from "./../../_models/org";
import { environment } from "./../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ListResultDTO } from "../../_helpers/listResult.interface";
import { EmployeeDTO } from 'src/app/_models/employee';

@Injectable({ providedIn: "root" })
export class LaravelOrderService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/order",
      list: environment["laravel"]["serverUrl"] + "/api/orders",
      store: environment["laravel"]["serverUrl"] + "/api/order",
      delete: environment["laravel"]["serverUrl"] + "/api/order",
      checkExistingServiceForOrg:
        environment["laravel"]["serverUrl"] + "/api/checkExistingServiceForOrg"
    };
  }

  getOrderById(id: number, include?: string[]): Observable<OrderDTO> {
    let params = {
      id: "" + id
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<OrderDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public getOrders(
    orgId: number,
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: string,
    include?: string[]
  ): Observable<ListResultDTO<OrderDTO>> {
    if (per_page) {
      return this.getPaginatedResults(
        orgId,
        page,
        per_page,
        order,
        direction,
        filter,
        include
      );
    } else {
      return this.getAllResults(orgId, order, direction, filter, include).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  private getPaginatedResults(
    orgId: number,
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: string,
    include?: string[]
  ): Observable<ListResultDTO<OrderDTO>> {
    let params = {};
    if (orgId) params["org_id"] = "" + orgId;
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) params["filter"] = "" + filter;

    return this.httpClient.get<ListResultDTO<OrderDTO>>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  private getAllResults(
    orgId: number,
    order: string,
    direction: string,
    filter?: string,
    include?: string[]
  ): Observable<OrderDTO[]> {
    let params = {};

    if (orgId) params["org_id"] = "" + orgId;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) params["filter"] = "" + filter;

    return this.httpClient.get<OrderDTO[]>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public createOrder(dto: OrderDTO): Observable<OrderDTO> {
    return this.httpClient.post<OrderDTO>(this.ROUTES.store, {
      order: dto
    });
  }

  public updateOrder(id: number, dto: OrderDTO): Observable<OrderDTO> {
    dto.id = id;
    return this.httpClient.put<OrderDTO>(this.ROUTES.store, {
      order: dto
    });
  }

  public deleteOrder(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public checkExistingServiceForOrg(
    serviceId: number,
    orgId: number,
    includeArchived?: boolean
  ): Observable<boolean> {
    let params = {};
    if (serviceId) params["service_id"] = "" + serviceId;
    if (orgId) params["org_id"] = "" + orgId;
    if (includeArchived) params["include_archived"] = "true";
    return this.httpClient
      .get<number>(this.ROUTES.checkExistingServiceForOrg, {
        params: new HttpParams({ fromObject: params })
      })
      .pipe(map(count => count > 0));
  }
}
