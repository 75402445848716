import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProjectFilters } from 'src/app/_models/project';

import { PROJECT_AREAS, PROJECT_CLASSES } from './../../_models/project';


@Component({
  selector: 'app-project-filters',
  templateUrl: './project-filters.component.html',
  styleUrls: ['./project-filters.component.scss']
})
export class ProjectFiltersComponent {

  PROJECT_AREAS = PROJECT_AREAS;
  PROJECT_CLASSES = PROJECT_CLASSES;

  @Output()
  onFilter: EventEmitter<Partial<ProjectFilters>> = new EventEmitter();

  private _defaultFilters: Partial<ProjectFilters>;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      client: "",
      area: "",
      offer_number: "",
      order_number: "",
      identifier: "",
      class: "",
      open_from: "",
      open_to: "",
      include_closed: "",
      closed_from: "",
      closed_to: "",
      accountant: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getProjectFilters());
  }

  private _getProjectFilters(): Partial<ProjectFilters> {
    let filters: Partial<ProjectFilters> = {};
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.client = values.client ? values.client.toDTO() : null;
      filters.area = values.area;
      filters.offer_number = values.offer_number;
      filters.order_number = values.order_number;
      filters.identifier = values.identifier;
      filters.class = values.class;
      filters.open_from = values.open_from;
      filters.open_to = values.open_to;
      filters.include_closed = values.include_closed;
      filters.closed_from = values.closed_from;
      filters.closed_to = values.closed_to;
      filters.accountant = values.accountant ? values.accountant.toDTO() : null;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): Partial<ProjectFilters> {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: Partial<ProjectFilters>) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          client: this.defaultFilters.client,
          area: this.defaultFilters.area,
          offer_number: this.defaultFilters.offer_number,
          order_number: this.defaultFilters.order_number,
          identifier: this.defaultFilters.identifier,
          class: this.defaultFilters.class,
          open_from: this.defaultFilters.open_from,
          open_to: this.defaultFilters.open_to,
          include_closed: this.defaultFilters.include_closed,
          closed_from: this.defaultFilters.closed_from,
          closed_to: this.defaultFilters.closed_to,
          accountant: this.defaultFilters.accountant,
        })
      }
    }
  }
}
