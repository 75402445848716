import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { PAGE_SIZE_OPTIONS } from 'src/app/_helpers/table.helper';
import { Article, ArticleFilters } from 'src/app/_models/article';
import { Observable } from 'rxjs';

export type ArticlesColumn = "code" | "name" | "area" | "expense" | "actions";

@Component({
  selector: 'article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
})
export class ArticleListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  articles: Observable<Article[]>;
  @Input()
  showExpenseFilter: boolean = true;

  @Input()
  defaultFilters: Partial<ArticleFilters>;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Article | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ArticlesColumn[] = ["code", "name", "area", "expense", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<Partial<ArticleFilters>> = new EventEmitter<Partial<ArticleFilters>>();

  @Output()
  onSelectArticle: EventEmitter<Article> = new EventEmitter<Article>();
  @Output()
  onAddArticle: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditArticle: EventEmitter<Article> = new EventEmitter<Article>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addArticle() {
    this.onAddArticle.emit();
  }

  editArticle(article: Article) {
    this.onEditArticle.emit(article);
  }

  selectArticle(article: Article) {
    this.onSelectArticle.emit(article);
  }

  onFilter(filters: Partial<ArticleFilters>) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
