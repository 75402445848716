<header mat-dialog-title>
  Scegli corso pianificato
</header>
<mat-dialog-content style="width: 60vw">
  <div class="mat-elevation-z2">
    <mat-toolbar class="flex">
      <span></span>
      <form [formGroup]="filtersForm" (onSubmit)="reload()" novalidate (keyup.enter)="reload()">
        <mat-form-field floatLabel="never">
          <mat-label>Stato</mat-label>
          <mat-select formControlName="statuses" multiple>
            <mat-select-trigger>
              {{filtersForm.value.statuses ? plannedOrderStatuses[filtersForm.value.statuses[0]] : ''}}
              <span *ngIf="filtersForm.value.statuses?.length > 1" class="additional-selection">
                (+{{filtersForm.value.statuses.length - 1}}
                {{filtersForm.value.statuses?.length === 2 ? 'altro' : 'altri'}})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let status of plannedOrderStatuses | keys" [value]="status">
              {{plannedOrderStatuses[status]}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="never">
          <input matInput type="text" placeholder="Cerca" formControlName="query" />
        </mat-form-field>
        <button type="button" mat-icon-button color="primary">
          <mat-icon aria-label="Start search button" (click)="reload()">search</mat-icon>
        </button>
        <button type="button" mat-icon-button color="primary" *ngIf="!filtersForm.pristine">
          <mat-icon aria-label="Clear search button" (click)="initFiltersForm(); reload()">close</mat-icon>
        </button>
      </form>
    </mat-toolbar>
    <div [hidden]="(dataSource.loading$ | async) || (dataSource.total$ | async) == 0">
      <mat-table #table [dataSource]="dataSource" class="table" matSort matSortActive="created_at" matSortDisableClear
        matSortDirection="asc">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && (allSelected | async)"
              [indeterminate]="selection.hasValue() && (allSelected | async)">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [disabled]="!canSelect(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.objectId }}
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="course">
          <mat-header-cell *matHeaderCellDef mat-sort-header="course">Corso</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.parent" #tooltip="matTooltip"
              [matTooltip]="'Corso satellite di ' + row.parent.service.name">device_hub</mat-icon>
            {{ row.service.code }} - {{ row.service.name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="from">
          <mat-header-cell *matHeaderCellDef mat-sort-header="from">Dal</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.startDate | date: 'dd-MM-yyyy' | default: '-'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="to">
          <mat-header-cell *matHeaderCellDef mat-sort-header="to">Al</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.endDate | date: 'dd-MM-yyyy' | default: '-'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header="status">Stato</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <app-course-status [plannedCourse]="row"></app-course-status>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-raised-button color="primary" [disabled]="!canSelect(row)"
              (click)="selectPlannedCourse(row)">Seleziona</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <mat-paginator [length]="dataSource.total$ | async" [pageSize]="25" [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf="multiple" [disabled]="!(selection?.selected?.length > 0)" mat-raised-button color="primary"
    (click)="confirmMultiple()">Conferma</button>
  <button mat-raised-button color="basic" (click)="close()">Chiudi</button>
</mat-dialog-actions>