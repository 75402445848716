import { createAction, props } from "@ngrx/store";
import { User, UserDTO, UserFilters } from "src/app/_models/user";

export const selectUser = createAction('[Users] Select user', props<{ filters: UserFilters }>());
export const selectionDialogOpened = createAction('[Users] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Users] Close selection dialog');
export const userSelected = createAction('[Users] User selected', props<{ user: UserDTO }>());

export const changeUserPassword = createAction('[Users] Change user password', props<{ user?: UserDTO }>());
export const changePasswordDialogOpen = createAction('[Users] Change password dialog opened', props<{ dialogId: string }>());
export const closeChangePasswordDialog = createAction('[Users] Close change password dialog');


export const updatePassword = createAction('[Users] Update password', props<{ newPassword: string, user?: User }>());
export const updatePasswordCompleted = createAction('[Users] Update password completed', props<{ user: UserDTO }>());
export const updatePasswordFailed = createAction('[Users] Update password failed', props<{ error: any }>());
