import { Org } from "./../../../_models/org";
import { EmployeeService } from "./../../../_services/employee.service";
import { Observable } from "rxjs";
import { Employee, EmployeeFilter } from "./../../../_models/employee";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";

export class EmployeeDataSource implements DataSource<Employee> {
  private employeeSubject = new BehaviorSubject<Employee[]>([]);

  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.totalSubject.asObservable();

  constructor(private employeeService: EmployeeService) {}

  connect(): Observable<Employee[]> {
    return this.employeeSubject.asObservable();
  }

  disconnect(): void {
    this.employeeSubject.complete();
    this.loadingSubject.complete();
    this.totalSubject.complete();
  }

  get data(): Observable<Employee[]> {
    return this.employeeSubject.asObservable();
  }

  loadEmployees(
    org: Org,
    onlyPrivate: boolean,
    page: number = 0,
    perPage: number = 25,
    order: string = "name",
    direction: string = "",
    filter?: EmployeeFilter,
    include?: string | string[]
  ) {
    this.loadingSubject.next(true);

    this.employeeService
      .getEmployees(
        org,
        onlyPrivate,
        page,
        perPage,
        order,
        direction,
        filter,
        include
      )
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        result => {
          this.totalSubject.next(result.total);
          this.employeeSubject.next(result.data);
        },
        error => {
          console.error("Error", error);
          this.totalSubject.next(0);
          this.employeeSubject.next([]);
        }
      );
  }
}
