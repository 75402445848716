import { Observable } from "rxjs";
import { PlannedCourse } from "./../../../_models/planning";
import {
  PlanningService,
  PlannedCourseFilters
} from "./../../../_services/planning.service";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";

export class PlannedCourseDataSource implements DataSource<PlannedCourse> {
  private coursesSubject = new BehaviorSubject<PlannedCourse[]>([]);

  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.totalSubject.asObservable();

  constructor(private planningService: PlanningService) {}

  connect(): Observable<PlannedCourse[]> {
    return this.coursesSubject.asObservable();
  }

  disconnect(): void {
    this.coursesSubject.complete();
    this.loadingSubject.complete();
    this.totalSubject.complete();
  }

  get data(): Observable<PlannedCourse[]> {
    return this.coursesSubject.asObservable();
  }

  loadPlannedCourses(
    page: number = 0,
    perPage: number = 25,
    order: string = "course",
    direction: string = "",
    filter?: PlannedCourseFilters,
    include?: string | string[]
  ) {
    this.loadingSubject.next(true);

    this.planningService
      .getPlannedCourses(page, perPage, order, direction, filter, include)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        result => {
          this.totalSubject.next(result.total);
          this.coursesSubject.next(result.data);
        },
        error => {
          console.error("Error", error);
          this.totalSubject.next(0);
          this.coursesSubject.next([]);
        }
      );
  }
}
