import {
  PlannedUnitService,
  PlannedUnitFilter
} from "./../../_services/planned-unit.service";
import { Observable } from "rxjs";
import { PlannedUnit } from "./../../_models/planning";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";

export class PlannedUnitDataSource implements DataSource<PlannedUnit> {
  private unitsSubject = new BehaviorSubject<PlannedUnit[]>([]);

  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.totalSubject.asObservable();

  constructor(private plannedUnitService: PlannedUnitService) {}

  connect(): Observable<PlannedUnit[]> {
    return this.unitsSubject.asObservable();
  }

  disconnect(): void {
    this.unitsSubject.complete();
    this.loadingSubject.complete();
    this.totalSubject.complete();
  }

  get data(): Observable<PlannedUnit[]> {
    return this.unitsSubject.asObservable();
  }

  loadPlannedUnits(
    page: number = 0,
    perPage: number = 25,
    order: string = "unit",
    direction: string = "",
    filter?: PlannedUnitFilter,
    include?: string | string[]
  ) {
    this.loadingSubject.next(true);

    this.plannedUnitService
      .getPlannedUnits(page, perPage, order, direction, filter, include)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        result => {
          this.totalSubject.next(result.total);
          this.unitsSubject.next(result.data);
        },
        error => {
          console.error("Error", error);
          this.totalSubject.next(0);
          this.unitsSubject.next([]);
        }
      );
  }
}
