import { map } from "rxjs/operators";
import { EducationUnitDTO } from "./../../_models/service";
import { environment } from "./../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ListResultDTO } from "../../_helpers/listResult.interface";

@Injectable({ providedIn: "root" })
export class LaravelEducationUnitService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/educationUnit",
      list: environment["laravel"]["serverUrl"] + "/api/educationUnits",
      store: environment["laravel"]["serverUrl"] + "/api/educationUnit",
      delete: environment["laravel"]["serverUrl"] + "/api/educationUnit"
    };
  }

  getEducationUnitById(
    id: number,
    include?: string[]
  ): Observable<EducationUnitDTO> {
    let params = {
      id: "" + id
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<EducationUnitDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public getEducationUnits(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: string,
    include?: string[]
  ): Observable<ListResultDTO<EducationUnitDTO>> {
    if (per_page) {
      return this.getPaginatedResults(
        page,
        per_page,
        order,
        direction,
        filter,
        include
      );
    } else {
      return this.getAllResults(order, direction, filter, include).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  private getPaginatedResults(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: string,
    include?: string[]
  ): Observable<ListResultDTO<EducationUnitDTO>> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) params["filter"] = "" + filter;

    return this.httpClient.get<ListResultDTO<EducationUnitDTO>>(
      this.ROUTES.list,
      {
        params: new HttpParams({ fromObject: params })
      }
    );
  }

  private getAllResults(
    order: string,
    direction: string,
    filter?: string,
    include?: string[]
  ): Observable<EducationUnitDTO[]> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) params["filter"] = "" + filter;

    return this.httpClient.get<EducationUnitDTO[]>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public createEducationUnit(
    dto: EducationUnitDTO
  ): Observable<EducationUnitDTO> {
    return this.httpClient.post<EducationUnitDTO>(this.ROUTES.store, {
      education_unit: dto
    });
  }

  public updateEducationUnit(
    id: number,
    dto: EducationUnitDTO
  ): Observable<EducationUnitDTO> {
    dto.id = id;
    return this.httpClient.put<EducationUnitDTO>(this.ROUTES.store, {
      education_unit: dto
    });
  }

  public deleteEducationUnit(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }
}
