import {createAction, props} from '@ngrx/store';
import {BillingLine, BillingLineDTO, BillingLineFilters} from 'src/app/_models/billing-line.model';

export const loadBillingLines = createAction('[BillingLines] Load BillingLines', props<{page: number, perPage: number, order?: string, direction?: string, filters?: Partial<BillingLineFilters>, includes?: string[]}>());
export const loadBillingLinesCompleted = createAction('[BillingLines] Load BillingLines Completed', props<{billingLines: BillingLineDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: Partial<BillingLineFilters>, includes?: string[]}>());
export const loadBillingLinesFailed = createAction('[BillingLines] Load BillingLines Failed', props<{error: any}>());

export const changePage = createAction('[BillingLines] Change page', props<{page: number, size: number}>());
export const changeSort = createAction('[BillingLines] Change sort', props<{order: string, direction: string}>());
export const changeFilters = createAction('[BillingLines] Change filters', props<{filters: Partial<BillingLineFilters>}>());

export const editBillingLine = createAction('[BillingLines] Edit billingLine', props<{multiclient: boolean, billingLine?: BillingLineDTO}>());
export const billingLineDialogOpened = createAction('[BillingLines] Detail dialog opened', props<{dialogId: string}>());
export const closeBillingLineDialog = createAction('[BillingLines] Close detail dialog');

export const saveBillingLine = createAction('[BillingLines] Save billingLine', props<{billingLine: BillingLine}>());
export const saveBillingLineCompleted = createAction('[BillingLines] Save billingLine completed', props<{billingLine: BillingLineDTO}>());
export const saveBillingLineFailed = createAction('[BillingLines] Save billingLine failed', props<{error: any}>());

export const deleteBillingLine = createAction('[BillingLines] Delete billingLine', props<{billingLine: BillingLineDTO}>());
export const deleteBillingLineCompleted = createAction('[BillingLines] Delete billingLine completed', props<{billingLine: BillingLineDTO}>());
export const deleteBillingLineCancelled = createAction('[BillingLines] Delete billingLine cancelled');
export const deleteBillingLineFailed = createAction('[BillingLines] Delete billingLine failed', props<{error: any}>());

export const selectBillingLine = createAction('[BillingLines] Select billingLine', props<{filters?: BillingLineFilters}>());
export const selectionDialogOpened = createAction('[BillingLines] Selection dialog opened', props<{selectionDialogId: string}>());
export const closeSelectionDialog = createAction('[BillingLines] Close selection dialog');
export const billingLineSelected = createAction('[BillingLines] BillingLine selected', props<{billingLine: BillingLineDTO}>());

export const generateBillingLine = createAction('[BillingLines] Generate billingLine', props<{projectId: number, sobstitute?: boolean}>());
export const generateBillingLineCompleted = createAction('[BillingLines] Generate billingLine completed', props<{billingLine: BillingLineDTO}>());
export const generateBillingLineFailed = createAction('[BillingLines] Generate billingLine failed', props<{error: any}>());

export const addSelectBillingLines = createAction('[BillingLines] Add selected billingLines', props<{billingLine: BillingLine}>());
export const removeSelectedBillingLines = createAction('[BillingLines] Remove selected billingLines', props<{billingLine: BillingLine}>());
export const resetSelectedBillingLines = createAction('[BillingLines] Reset selected billingLines');
