<div class="login-page">
  <div class="content">
    <form class="login-form" name="form" [formGroup]="loginForm">
      <h2 class="u-center-text">COSMO</h2>
      <div style="text-align: center;">
        <mat-hint>Inserisci e-mail e password per accedere</mat-hint>
      </div>
      <div>
        <div>
          <mat-form-field class="u-full-width">
            <input matInput type="email" placeholder="E-mail" formControlName="username" required>
            <mat-error *ngIf="loginForm.controls['username'].errors">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <div>
          <mat-form-field class="u-full-width">
            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password"
              required>
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
            <mat-error *ngIf="loginForm.controls['password'].errors">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <div>
          <button mat-raised-button class="u-full-width" color="primary" [disabled]="!loginForm.valid"
            (click)="login()">Accedi</button>
        </div>
      </div>
    </form>
  </div>
</div>