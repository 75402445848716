import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Attachment } from 'src/app/_models/attachment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: "app-attachment-list",
  templateUrl: "./attachment-list.component.html",
  styleUrls: ["./attachment-list.component.scss"]
})
export class AttachmentListComponent implements OnInit {
  attachmentsDatasource = new BehaviorSubject<AbstractControl[]>([]);
  attachmentsDisplayedColumns: string[] = [
    "add",
    "file",
    "type",
    "description"
  ];

  @Input()
  form: UntypedFormGroup;
  @Input()
  types: string[];

  private _attachments: Attachment[];

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() { }

  addAttachment(attachment?: Attachment) {
    let group = {
      objectId: [attachment ? attachment.objectId : null],
      file: ["", Validators.required],
      type: ["", Validators.required],
      description: [""]
    };

    const attachmentCtrl = this.fb.group(group);

    if (attachment) {
      attachmentCtrl.patchValue({
        objectId: attachment.objectId,
        file: attachment.url || attachment.file,
        type: attachment.type,
        description: attachment.description
      });
    }

    this.attachmentsForm.push(attachmentCtrl);
    this.attachmentsDatasource.next(this.attachmentsForm.controls);
  }

  private resetAttachmentsForm() {
    while (this.attachmentsForm.length !== 0) {
      this.attachmentsForm.removeAt(0);
    }
    this.attachmentsDatasource.next(this.attachmentsForm.controls);
  }

  removeAttachment(index) {
    this.attachmentsForm.removeAt(index);
    this.form.markAsDirty();
    this.attachmentsDatasource.next(this.attachmentsForm.controls);
  }

  get attachmentsForm(): UntypedFormArray {
    return this.form.get("attachments") as UntypedFormArray;
  }

  get attachments(): Attachment[] {
    return this._attachments;
  }

  @Input()
  set attachments(attachments: Attachment[]) {
    this._attachments = attachments;
    if (attachments) {
      attachments.forEach(attachment => this.addAttachment(attachment));
    }
  }
}
