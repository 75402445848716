import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

const DEFAULT_WARNING_DAYS_KEY = "DEFAULT_WARNING_DAYS";
const SENDER_ADDRESSES_KEY = "SENDER_ADDRESSES";

@Injectable({ providedIn: "root" })
export class LaravelConfigService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      load: environment["laravel"]["serverUrl"] + "/api/config/all"
    };
  }

  private config: any = {};

  load(): Observable<boolean> {
    return this.httpClient.get<any[]>(this.ROUTES.load).pipe(
      map(loadedConfig => {
        loadedConfig.forEach(c => {
          this.config[c.name] = c.value;
        })
        return true;
      })
    );
  }

  get defaultWarningDays(): number {
    return this.config[DEFAULT_WARNING_DAYS_KEY];
  }

  get senderAddresses(): string[] {
    return JSON.parse(this.config[SENDER_ADDRESSES_KEY]);
  }
}
