import { createSelector } from '@ngrx/store';
import { CalendarEvent } from 'angular-calendar';
import { Activity, ActivityDTO } from 'src/app/_models/activity';
import { User } from 'src/app/_models/user';
import { getCurrentUser, selectActivityState } from 'src/app/_store';
import { currentUser } from 'src/app/_store/app.reducer';

import { ActivityState } from './activity.reducer';


// Activity Selectors

export const getActivitiesTableState = createSelector(
  selectActivityState,
  (state: ActivityState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getActivities = createSelector(
  selectActivityState,
  (state: ActivityState) => state.list
);

// TO FIX
// export const getActivityByUser = createSelector(
//   getActivities,
//   (activities: ActivityDTO[]) =>
// );

export const getTotalActivities = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.total
);

export const getActivitiesCurrentPage = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.currentPage
);

export const getActivitiesPerPage = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.perPage
);

export const getActivitiesOrder = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.order
);

export const getActivitiesDirection = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.filters
);

export const getActivityDialogId = createSelector(
  selectActivityState,
  (state: ActivityState) => state.dialogId
);

export const getSelectionDialogId = createSelector(
  selectActivityState,
  (state: ActivityState) => state.selectionDialogId
);

export const getCurrentActivity = createSelector(
  selectActivityState,
  (state: ActivityState) => state.currentActivity
);

export const getActivityId = createSelector(
  selectActivityState,
  (state: ActivityState) => state.activityId
);

export const getFiltersReport = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.filters
);

export const getUserIds = createSelector(
  getCurrentUser,
  selectActivityState,
  (currentUser: User, state: ActivityState) => {
    if(state.userIds.length) {
      return state.userIds;
    }else{
      return [currentUser?.objectId]
    }
  }
);

export const getCalendarEvents = createSelector(
  getActivities,
  getUserIds,
  (activities: ActivityDTO[], userIds: number[]) => {
    const result = [];
    activities.forEach(activityDTO => {
      const filteredArray = activityDTO.users.map(user => user.id).filter(value => userIds.includes(value));
      if(filteredArray.length > 0) {
        activityDTO.users.forEach(userDTO => {
          if(userIds.includes(userDTO.id)){
            const user = new User(userDTO)
            const activity = new Activity(activityDTO)
            const calendarEvent: CalendarEvent = {
              color: {
                primary: user.calendarColor || '#000000',
                 secondary: user.calendarColor || '#000000'
                 },
               start: activity.startDate,
             end: activity.endDate,
             title: `${activity.project?.identifier} - ${activity.article?.name} (${user.fullName})`,
             meta: activity,
             draggable: true,
             resizable: {
               beforeStart: true, // this allows you to configure the sides the event is resizable from
               afterEnd: true
             }
           }
           result.push(calendarEvent);
          }
        });
      }
    });
    return result;
  }
);
