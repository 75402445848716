import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatDateForBackend } from 'src/app/_helpers/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ListResultDTO } from '../../_helpers/listResult.interface';
import { PresenceDTO, PresenceFilters, RecoveryDetailsDTO } from './../../_models/planning';

@Injectable({ providedIn: "root" })
export class LaravelAbsenceService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/absence",
      list: environment["laravel"]["serverUrl"] + "/api/absences",
      add_recovery: environment["laravel"]["serverUrl"] + "/api/add_recovery",
    };
  }

  getAbsenceById(id: number, include?: string[]): Observable<PresenceDTO> {
    let params = {
      id: "" + id,
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<PresenceDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params,
      }),
    });
  }

  public getAbsences(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filters?: PresenceFilters,
    include?: string[]
  ): Observable<ListResultDTO<PresenceDTO>> {
    if (per_page) {
      return this.getPaginatedResults(
        page,
        per_page,
        order,
        direction,
        filters,
        include
      );
    } else {
      return this.getAllResults(order, direction, filters, include).pipe(
        map((results) => {
          return {
            data: results,
            total: results.length,
          };
        })
      );
    }
  }

  private getPaginatedResults(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filters?: PresenceFilters,
    include?: string[]
  ): Observable<ListResultDTO<PresenceDTO>> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filters) {
      if (filters.courseQuery) params["course_query"] = filters.courseQuery;
      if (filters.employeeQuery)
        params["employee_query"] = filters.employeeQuery;
      if (filters.start)
        params["start_date"] = formatDateForBackend(filters.start, false);
      if (filters.end)
        params["end_date"] = formatDateForBackend(filters.end, false);
      if (filters.unitIds) params["unit_ids[]"] = filters.unitIds;
    }

    return this.httpClient.get<ListResultDTO<PresenceDTO>>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params }),
    });
  }

  private getAllResults(
    order: string,
    direction: string,
    filters?: PresenceFilters,
    include?: string[]
  ): Observable<PresenceDTO[]> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filters) {
      if (filters.courseQuery) params["course_query"] = filters.courseQuery;
      if (filters.employeeQuery)
        params["employee_query"] = filters.employeeQuery;
      if (filters.start)
        params["start_date"] = formatDateForBackend(filters.start, false);
      if (filters.end)
        params["end_date"] = formatDateForBackend(filters.end, false);
      if (filters.unitIds) params["unit_ids[]"] = filters.unitIds;
    }

    return this.httpClient.get<PresenceDTO[]>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params }),
    });
  }

  public addRecovery(
    presence_id: number,
    planned_unit_id: number,
    details: RecoveryDetailsDTO
  ): Observable<PresenceDTO> {
    return this.httpClient.put<PresenceDTO>(this.ROUTES.add_recovery, {
      presence_id: presence_id,
      planned_unit_id: planned_unit_id,
      details: details,
    });
  }
}
