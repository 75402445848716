import { PlannedCourseStatusEnum } from "./../../_models/planning";
import { Component, OnInit, Input } from "@angular/core";
import { PlannedCourse } from "src/app/_models/planning";

@Component({
  selector: "app-course-status",
  templateUrl: "./course-status.component.html",
  styleUrls: ["./course-status.component.scss"]
})
export class CourseStatusComponent implements OnInit {
  PlannedCourseStatusValues = PlannedCourseStatusEnum;
  constructor() { }

  ngOnInit() { }

  @Input()
  plannedCourse: PlannedCourse;

  getTooltip() {
    switch (PlannedCourseStatusEnum[this.plannedCourse.status]) {
      case PlannedCourseStatusEnum.draft:
        return "Il corso non può essere pianificato: non tutte le unità formative necessarie sono state pianificate";
      case PlannedCourseStatusEnum.planned:
        return "Corso pianificato: tutte le unità formative necessarie sono state pianificate";
      case PlannedCourseStatusEnum.completed:
        return "Corso completato: il corso si è concluso";
      case PlannedCourseStatusEnum.cancelled:
        return "Corso annullato";

      default:
        return "";
    }
  }
}
