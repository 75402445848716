<header mat-dialog-title>Dettagli recupero</header>
<mat-dialog-content>
  <div class="mat-elevation-z2" style="padding: 15px">
    <form [formGroup]="form">
      <mat-form-field>
        <input
          type="number"
          placeholder="Costo recupero"
          matInput
          formControlName="cost"
        />
        <span matPrefix>€&nbsp;</span>
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-select matInput formControlName="status" placeholder="Stato">
          <mat-option value="billable">Non fatturato</mat-option>
          <mat-option value="billed">Fatturato</mat-option>
          <mat-option value="paid">Pagato</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="basic" (click)="cancel()">Annulla</button>
  <button mat-raised-button color="primary" (click)="close()">Conferma</button>
</mat-dialog-actions>
