import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Attachment } from './../../_models/attachment';
import { AlertService } from './../../_services/alert.service';
import { PlanningService } from './../../_services/planning.service';

@Component({
  selector: "app-attachment-upload",
  templateUrl: "./attachment-upload.component.html",
  styleUrls: ["./attachment-upload.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AttachmentUploadComponent,
      multi: true
    }
  ]
})
export class AttachmentUploadComponent implements ControlValueAccessor {
  fileUrl: string;
  onChange: Function;
  file: File | null = null;

  @Input()
  attachment: Attachment;

  @HostListener("change", ["$event.target.files"]) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.file = file;
    this.onChange(this.file);
  }

  constructor(private host: ElementRef<HTMLInputElement>, private planningService: PlanningService, private alertService: AlertService) { }

  ngOnInit() { }

  writeValue(value) {
    // clear file input
    this.host.nativeElement.value = "";
    this.file = value;

    if (value) {
      if (typeof value == "string") {
        this.fileUrl = value;
      }
    }
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) { }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.registerOnTouched;
    }
  }

  delete() {
    this.file = null;
    this.fileUrl = null;
    this.onChange(null);
  }

  @ViewChild("downloadLink")
  private downloadLink: ElementRef;


  downloadFile() {
    if (this.attachment) {
      this.planningService
        .downloadFile(this.attachment.objectId)
        .subscribe(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = this.downloadLink.nativeElement;
          link.href = url;
          link.download = this.attachment.filename;
          link.click();
          window.URL.revokeObjectURL(url);
          // this.alertService.showConfirmMessage('Attestato generato con successo');
        }, error => {
          this.alertService.showErrorMessage(`Errore nello scaricamento del file ${this.attachment.filename}`, error);
        });
    }

  }


}
